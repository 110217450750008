.headermenu {
  display: flex;
}

.headermenu > li {
  position: relative;
}

li {
  list-style: none;
}

.cursorpointer {
  cursor: pointer;
  text-transform: capitalize;
}



.sb-float-right-margin {
  float: right;
  margin: 2% 2% 0 0;
}
