 /* html,
body {
  -webkit-overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  font-family: 'Montserrat', sans-serif;
} */

.margintopleft {
  margin-top: 94px;
  margin-left: 260px;
 
  width: 100%;
  width: -moz-available;          
  width: -webkit-fill-available;  
  width: fill-available;
  

}

@media(max-width:1200px) {
  .margintopleft {
    margin-left: 0;
  }
}

.widthlogoadmin {
  width: 150px;
}

.centertitleadmin {
  display: flex;
  width: 100%;
  align-items: flex-end;
}


.blackcolor {
  color: black !important;
  border-bottom: 0 !important;
}

.MuiTableCell-body {
  color: black !important;
}
.MuiSvgIcon-colorPrimary,.MuiTypography-colorPrimary{
  color: rgba(0,0,0,0.9) !important;
}
.MuiFab-primary,.MuiLinearProgress-barColorPrimary{
  background:#707070 !important;
}
.MuiTableCell-root {
  font-size: 1.1rem !important;
  padding: 10px !important;
  font-family: 'Sitka', serif !important;
}

.MuiAppBar-colorPrimary {
  background: linear-gradient(60deg, #031163, #05716C) !important;
}
.MuiButton-containedPrimary{
  background: linear-gradient(60deg, #031163, #05716C) !important;
}
.MuiButton-containedSecondary{
  background: #4b9e79 !important;
}
.MuiFormHelperText-root {
  font-size: 0.9rem !important;
}
.MuiButton-label {
  /* color: #fff; */
  font-family: 'Sitka', serif;
}

.overflowtable {
  overflow: auto;
}