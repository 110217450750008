.card {
	 display: flex;
	 flex-direction: column;
	 height: 100%;
	 border: 1px solid #eee;
	 align-items: flex-start;
	 padding: 3rem;
	 width: 100%;
}
 .card a {
	 color: #000;
}
 .card h3 {
	 font-weight: 600;
	 letter-spacing: 1px;
	 margin-bottom: 1.5rem;
	 width: 100%;
}
 .card p {
	 margin: 1.5rem 0;
	 width: 100%;
}
 .card .btn {
	 align-self: flex-end;
	 margin-top: auto;
}
 .card .extra {
	 display: flex;
}
 .card .extra a {
	 margin-right: 2rem;
	 font-size: 1.3rem;
	 display: flex;
	 align-items: center;
	 color: #ccc;
	 transition: all 0.3s ease;
}
 .card .extra a:hover {
	 color: #aaa;
}
 .card .extra i {
	 font-size: 2.5rem;
	 margin-right: 4px;
}
 @media (max-width: 576px) {
	 .card article {
		 padding: 15px;
	}
	 .card p {
		 margin: 10px 0;
	}
}