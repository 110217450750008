.flip-cardabout {
    background-color: transparent;
    width: 450px;
    height: 500px;
    perspective: 1000px;
    padding-top: 250px;
    padding-bottom: 50px;
}

@media(max-width:600px) {
    .flip-cardabout {
        padding-top: 120px;
    }
}

.flip-cardabout-inner {
    position: relative;
    width: 100%;
    height: 400px;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-cardabout:hover .flip-cardabout-inner {
    transform: rotateY(180deg);
}

.flip-cardabout-front,
.flip-cardabout-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-cardabout-front {
    background-color: #bbb;
    color: black;
}

.flip-cardabout-front img {
    width: 100%;
    height: 400px;
}

.flip-cardabout-back {
    background: linear-gradient(60deg, #031163, #05716C) !important;
    color: white;
    transform: rotateY(180deg);
}

.flip-cardabout-back img {
    width: 100%;
    height: 400px;
}

.borderabout {
    position: absolute;
    /* height: calc(100% + 10rem); */
    width: 60%;
    border: 1.5rem solid;
    top: 45%;
    transform: translateY(-50%);
    right: 0;
    border-color: rgb(9, 106, 141);
    padding-top: 250px;
    padding-bottom: 50px;
}


/* video section  */

.overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
}

.videoposition {
    width: 60%;
    /* height: 80%; */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
}

.videowidth {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media(max-width:1000px) {
    .videoposition {
        width: 80%;
        height: auto;
    }
}

@media(max-width:600px) {
    .mobcolumn {
        flex-direction: column-reverse;
    }
    .videoposition {
        width: 250px;
        height: auto;
    }
}

.closebtn {
    color: white;
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 1000;
    font-weight: bold;
    font-size: 25px;
    cursor: pointer;
    font-family: "JosefinSans-600", sans-serif;
}

.whytoattendtext {
    /* font-family: "JosefinSans-600", sans-serif; */
    font-size: 4rem;
    text-align: center;
}

@media(max-width:600px) {
    .whytoattendtext {
        font-size: 35px;
        margin-bottom: 10px;
    }
}

.popupbox {
    position: fixed;
    margin: 0 auto;
    padding: 20px 20px 20px;
    /* width: 320px; */
    width: 50%;
    background: rgb(255, 255, 255);
    border-radius: 3px;
    -webkit-transition: all 0.4s linear;
    -moz-transition: all 0.4s linear;
    -o-transition: all 0.4s linear;
    transition: all 0.4s linear;
    top: -50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: -1px 4px 28px 0px rgba(0, 0, 0, 0.75);
    z-index: 1000;
}


/****************************************************** */


/***************** 06. Video Section  **************** */


/****************************************************** */

.video-section {
    /* background: url(../images/video/video-bg.png) no-repeat right 30px; */
}

.video-inner {
    position: relative;
}

.video-inner:after {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    left: 30px;
    bottom: 35px;
    background: #031163;
}

.video-inner img {
    position: relative;
    z-index: 1;
}

.video-play {
    height: 76px;
    width: 76px;
    left: 50%;
    top: 50%;
    z-index: 2;
    color: #ca9c6a;
    font-size: 22px;
    line-height: 76px;
    position: absolute;
    border-radius: 50%;
    text-align: center;
    display: inline-block;
    background: rgba(0, 0, 0, 0.1);
    transform: translate(-50%, -50%) scale(1.25);
}

.video-play:before {
    content: '';
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    background: white;
    border-radius: 50%;
    position: absolute;
    transform: scale(0.8);
}