@media screen and (min-width:600px) and (max-width: 992px) {
    #aboustustext {
        margin-top: 170px !important;
    }
}

@media only screen and (max-width: 1100px) {
    #aboustustext {
        margin-top: 0 !important;
    }
}

.readmoretext {
    color: #031163;
    cursor: pointer;
    text-decoration: underline;
}

.video-section {
    margin-top: 50px;
}


/* 
.aboutpopup {
    background: white;
} */