.flexfooter {
    display: flex;
    justify-content: flex-end;
    padding: 0;
}

.flexfooter li {
    margin: 10px;
}

@media only screen and (max-width: 650px) {
    #footerwalstratlogo {
        width: 100px;
        margin-top: 35px;
    }
    .flexfooter {
        flex-direction: column;
        margin-left: 20px;
    }
    .flexfooter li {
        margin: 10px;
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 467px) {
    #footerwalstratlogo {
        width: 100px;
        margin-top: 35px;
    }
    .flexfooter {
        flex-direction: column;
        margin-left: 20px;
    }
    .flexfooter li {
        margin: 5px;
    }
}

@media only screen and (max-width: 767px) {
    #footerwalstratlogo {
        width: 100px;
        margin-top: 35px;
    }
    .flexfooter {
        flex-direction: column;
        margin-left: 20px;
    }
    .flexfooter li {
        margin: 5px;
    }
}

@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    #maincontent {
        overflow: auto;
    }
    #footerwalstratlogo {
        margin-top: 110px;
        padding: 10px;
    }
    .flexfooter {
        flex-direction: column;
        margin-left: 20px;
    }
    .flexfooter li {
        margin: 5px;
    }
}