/*--------------------------------------------------------------
# News One
--------------------------------------------------------------*/

:root {
    --thm-gray: #737373;
    --thm-gray-rgb: 115, 115, 115;
    --thm-primary: #9d9482;
    --thm-primary-rgb: 157, 148, 130;
    --thm-black: rgb(3, 17, 99);
    --thm-black-rgb: 0, 0, 0;
}

.news-one {
    position: relative;
    display: block;
    /* padding: 120px 0 90px; */
}

.news-one .section-title {
    margin-bottom: 47px;
}

.news-one__single {
    position: relative;
    display: block;
    margin-bottom: 30px;
    padding: 10px;
}

.news-one__img-box {
    position: relative;
    display: block;
}

.news-one-img {
    position: relative;
    display: block;
    overflow: hidden;
    height: 180px;
}

.news-one-img>img {
    width: 100%;
    height: 100%;
}

.news-one-img>a {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(var(--thm-black-rgb), 0.5);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    color: #ffffff;
    transition: opacity 500ms ease, visibility 500ms ease, transform 500ms ease;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-30%);
}

.news-one-img>a>span {
    position: relative;
}

.news-one-img>a>span::before {
    content: '';
    width: 20px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 500ms ease;
}

.news-one-img>a>span::after {
    content: '';
    transition: all 500ms ease;
    width: 2px;
    height: 20px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.news-one-img>a:hover>span::before,
.news-one-img>a:hover>span::after {
    background-color: var(--thm-primary);
}

.news-one__single:hover .news-one-img>a {
    visibility: visible;
    transform: translateY(0%);
    opacity: 1;
}

.news-one__date-box {
    position: absolute;
    bottom: -10px;
    right: 30px;
    background: var(--thm-black);
    padding: 3px 30px;
    z-index: 1;
}

.news-one__date-box p {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    color: #ffffff;
    margin: 0;
    letter-spacing: 0.1em;
}

.news-one__content {
    position: relative;
    display: block;
    text-align: center;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 20px 60px 0px rgb(23 39 54 / 10%);
    padding: 29px 50px 30px;
    transition: all 500ms ease;
}

.news-one__content:before {
    position: absolute;
    bottom: -5px;
    right: 0;
    left: 0;
    width: 0;
    content: "";
    background-color: var(--thm-black);
    height: 1px;
    transition: all 500ms ease;
}

.news-one__single:hover .news-one__content:before {
    width: 100%;
}

.news-one__single:hover .news-one__content {
    background-color: var(--thm-black);
}

.news-one__meta {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin: 0;
    justify-content: center;
}

.news-one__meta li {}

.news-one__meta li+li {
    margin-left: 15px;
}

.news-one__meta li a {
    font-size: 16px;
    color: var(--thm-gray);
    transition: all 500ms ease;
}

.news-one__single:hover .news-one__meta li a {
    color: #9d9d9d;
}

.news-one__meta li a i {
    color: var(--thm-primary);
    font-size: 14px;
}

.news-one__title {
    font-size: 26px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 39px;
    margin-bottom: 21px;
}

.news-one__title {
    color: var(--thm-black);
    transition: all 500ms ease;
    background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 89% / 0px 2px no-repeat;
}

.news-one__single:hover .news-one__title {
    color: #fff;
}

.news-one__single .news-one__title {
    background-size: 100% 2px;
}

.news-one__text {
    margin: 0;
    transition: all 500ms ease;
}

.news-one__single:hover .news-one__text {
    color: #9d9d9d;
}

.readMore {
    color: black !important;
    background: #0dcaf0 !important;
}
.readMore:hover {
    color: #9d9d9d;
    background: lightblue;
}

.news-one__btn {
    /* position: relative; */
    display: inline-block;
    font-size: 12px;
    color: var(--thm-black);
    font-weight: 500;
    text-transform: uppercase;
    transition: all 500ms ease;
    z-index: 1;
    margin-top: 19px;
}

.news-one__btn:hover {
    color: var(--thm-primary);
}

.news-one__single:hover .news-one__btn {
    color: #ffffff;
}

.news-one__btn:before {
    position: absolute;
    bottom: 10px;
    right: 0;
    left: 0;
    content: "";
    background-color: #e5e5e5;
    z-index: -1;
    height: 6px;
    transition: all 500ms ease;
}

.news-one__single:hover .news-one__btn:before {
    background-color: var(--thm-primary);
    opacity: 0.4;
}

.news-one__single .news-one__btn:hover {
    color: var(--thm-primary);
}


/* 
.news-one__page {
    padding-bottom: 120px;
} */

.news-one__more {
    margin-top: 30px;
}


/* Medium screen  */

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .news-one__content {
        padding: 29px 25px 30px;
    }
}


/* Mobile Layout: 320px. */

@media only screen and (max-width: 767px) {
    .news-one__content {
        padding: 29px 25px 30px;
    }
    .news-one .section-title__title {
        font-size: 29px;
        line-height: 40px;
    }
    .flexfooter{
        flex-direction: column;
        margin-left: 20px;
    }
    .flexfooter li{
        margin: 5px;
    }
    .Footer_footer__1T8hE a{
        font-size: 12px;
        margin: 0;
    }
}

@media (max-width: 576px)
{
    .Footer_footer__1T8hE p {
        font-size: 12px;
    }
}