.footer {
    display: flex;
    flex-wrap: wrap;
    color: #fff;
    & h4 {
        font-size: 1.6rem;
        font-weight: 400;
        letter-spacing: 1px;
        margin-bottom: 3.5rem;
    }
    & p {
        width: 100%;
        line-height: 1.3;
        font-size: 1.2rem;
        font-weight: 200;
        text-align: center;
    }
    & a {
        color: white;
        text-decoration: underline;
    }
}

.logo_area,
.link_area,
.subscribe {
    display: flex;
    flex-basis: 33.3333333%;
}

.logo_area {
    flex-direction: column;
    align-items: flex-start;
    padding-right: 5rem;
    & p {
        margin: 2.8rem 0;
    }
    & .social {
        display: flex;
        margin: 0 -0.8rem;
        & i {
            width: 2.8rem;
            height: 2.8rem;
            border-radius: 50%;
            background: #fff;
            color: #000;
            margin: 0 0.8rem;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.3rem;
        }
    }
}

.subscribe {
    flex-direction: column;
    padding-left: 5rem;
    & p {
        margin-top: 3rem;
    }
}

.links {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    align-items: flex-start;
    & a {
        color: inherit;
        font-size: 1.3rem;
        font-weight: 200;
        margin-bottom: 0.7rem;
        transition: all 0.3s ease;
    }
    & i {
        margin-right: 0.8rem;
    }
}

@media (max-width: 992px) {
    .logo_area,
    .link_area {
        flex-basis: 50%;
    }
    .subscribe {
        flex-basis: 100%;
        padding-left: 0;
        margin-top: 5rem;
        & p {
            margin-top: 2rem;
        }
    }
    .footer h4 {
        margin-bottom: 2rem;
    }
    .logo_area p {
        margin: 2rem 0;
    }
}

@media (max-width: 768px) {
    .logo_area {
        flex-basis: 40%;
    }
    .link_area {
        flex-basis: 60%;
    }
}

@media (max-width: 576px) {
    .footer h4 {
        margin-bottom: 1.3rem;
    }
    .footer p {
        font-size: 1.2rem;
    }
    .logo_area,
    .link_area {
        flex-basis: 100%;
    }
    .logo_area {
        padding: 0;
    }
    .link_area {
        margin-top: 3rem;
        & .links a {
            font-size: 1.2rem;
        }
        & .links i {
            margin-right: 0.5rem;
        }
    }
    .subscribe {
        margin-top: 2.5rem;
    }
}

// Internet Explorer 11
@media screen and (-ms-high-contrast: active),
screen and (-ms-high-contrast: none) {
    .logo_area,
    .subscribe {
        flex-basis: calc(33.3333333% - 5rem);
    }
}