.bgcolor {
    background: url("../../../assets/placeholders/industryslider.jpg");
    /* position: absolute; */
    width: 100%;
    height: 400px;
}

.bgcolor::before {
    content: '';
    background: linear-gradient(60deg, rgba(3, 21, 99, 0.3), rgba(5, 113, 108, 0.3)) !important;
    position: absolute;
    width: 100%;
    height: 400px;
}

.particleposition {
    position: fixed;
}

.title {
    position: absolute;
    top: 140px;
    left: 50%;
    transform: translate(-50%, 0%);
    text-transform: uppercase;
    font-family: verdana;
    font-size: 3.8em;
    font-weight: 700;
    color: #f5f5f5;
    text-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191, 1px 4px 1px #919191, 1px 5px 1px #919191, 1px 6px 1px #919191, 1px 7px 1px #919191, 1px 8px 1px #919191, 1px 9px 1px #919191, 1px 10px 1px #919191, 1px 18px 6px rgba(16, 16, 16, 0.4), 1px 22px 10px rgba(16, 16, 16, 0.2), 1px 25px 35px rgba(16, 16, 16, 0.2), 1px 30px 60px rgba(16, 16, 16, 0.4);
    text-align: center;
}

.tsparticles-canvas-el {
    position: absolute;
}

.servicesubtitle {
    text-align: center;
    margin-top: 100px;
    margin-bottom: 50px;
    font-size: 32px;
    /* background: -webkit-linear-gradient(45deg,#031163, #05716C); */
    background: linear-gradient(to right, #05716C 0%, #031163 100%);
    ;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.description {
    font-size: 1.8rem;
    text-align: justify;
    margin-top: 10px;
    margin-bottom: 20px;
    color: #031163;
    /* background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0.5) 10%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.75) 90%); */
}

@media(max-width:600px) {
    .description {
        font-size: 1.2rem;
    }
}


/* servicepage section */

.particlewidth {
    width: 100%;
    height: 400px;
}


/* servicepage3 section */

#video-viewport {
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
}


/* #video-viewport::after {
    content: '';
    background-color: rgba(255, 255, 255, 0.2);
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
} */


/* @media(max-width:600px){
  #video-viewport{
    height: 150vh;
  }
} */

video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* background-position: center; */
    object-position: center;
}


/* @media (max-aspect-ratio: 16/9) {
  video {
    width: auto;
    height: 100%;
  }
} */

.positiontext {
    /* position: absolute; */
    z-index: 3;
    width: 100%;
    padding-top: 30px;
    /* background: url(../../../assets/placeholders/beautiful-time-lapse-of-the-night-sky-Q5XDSRH.m4v); */
    /* height: 90%;
    overflow: auto; */
}

.titlepage3 {
    font-size: 4em;
    text-align: center;
    color: #031163;
    font-weight: bold;
}

@media(max-width:600px) {
    .titlepage3 {
        font-size: 2.5rem;
    }
}

.headerservices {
    position: absolute;
    width: 100%;
    height: 200px;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
}

.positionlogo {
    /* position: fixed; */
    z-index: 10;
    top: 10px;
    left: 10px;
    cursor: pointer;
}

.backtohomebtn {
    background: linear-gradient(60deg, #031163, #05716C)!important;
    color: white !important;
    border: 2px solid #fff !important;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    text-transform: uppercase !important;
    font-size: 1.7rem !important;
    line-height: 1.1 !important;
    font-weight: 600 !important;
    letter-spacing: 1px !important;
    transition: all 0.3s ease !important;
    padding: 1.3rem 2.9rem !important;
    /* width: 100%; */
    outline: none !important;
    font-family: 'Sitka', serif !important;
}

@media(max-width:600px) {
    .backtohomebtn {
        font-size: 1.2rem !important;
    }
}

.serviceheader {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    transition: all 1s ease;
    background: linear-gradient(60deg, #031163, #05716C) !important;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.parti {
    height: 100%;
    z-index: 122;
    position: absolute;
    width: 100%;
}


/* services icon position */

.positionserviceicon {
    position: absolute;
    width: 30px;
}

@media(max-width:600px) {
    .positionserviceicon {
        width: 20px;
    }
}


/* top gif position */

.topgif {
    animation: rocket 1s cubic-bezier(0.36, 0.07, 0.57, 0.99) infinite;
}

.widthtopgif {
    width: 20px;
    margin-left: 10px;
}


/* bottom gif position */

@keyframes rocket {
    0% {
        transform: scale(1.5) translate(0, 0);
        opacity: 1;
    }
    5% {
        transform: scale(1.5) translate(2px, 0);
        opacity: 1;
    }
    10% {
        transform: scale(1.5) translate(1px, -2px);
        opacity: 1;
    }
    15% {
        transform: scale(1.5) translate(3px, -1px);
        opacity: 1;
    }
    20% {
        transform: scale(1.5) translate(2px, -3px);
        opacity: 1;
    }
    25% {
        transform: scale(1.5) translate(4px, -2px);
        opacity: 1;
    }
    50% {
        transform: scale(1.5) translate(10px, -10px);
        opacity: 0;
    }
    51% {
        transform: scale(1.5) translate(-10px, 10px);
        opacity: 0;
    }
    100% {
        transform: scale(1.5) translate(0, 0);
        opacity: 1;
    }
}


/* education animation */

.topeducationicon {
    animation: arrow-right 0.5s cubic-bezier(0.36, 0.07, 0.57, 0.99) infinite;
}

@keyframes arrow-right {
    0% {
        transform: scale(1.5) translateX(0);
    }
    33% {
        transform: scale(1.5) translateX(-5px);
    }
    66% {
        transform: scale(1.5) translateX(5px);
    }
    100% {
        transform: scale(1.5) translateX(0);
    }
}


/* automobile icon animation */

.topautomobileicon {
    animation: refresh 0.5s cubic-bezier(0.36, 0.07, 0.57, 0.99) infinite;
}

@keyframes refresh {
    from {
        transform: scale(1.5) rotate(0);
    }
    to {
        transform: scale(1.5) rotate(360deg);
    }
}


/* research animation  */

.topresearchicon {
    animation: italic 0.5s cubic-bezier(0.36, 0.07, 0.57, 0.99) infinite;
}

@keyframes arrows-alt {
    0% {
        transform: scale(1.5) translate(0, 0);
    }
    20% {
        transform: scale(1.5) translate(-5px, 5px);
    }
    40% {
        transform: scale(1.5) translate(-5px, -5px);
    }
    60% {
        transform: scale(1.5) translate(5px, 5px);
    }
    80% {
        transform: scale(1.5) translate(5px, -5px);
    }
    100% {
        transform: scale(1.5) translate(0, 0);
    }
}


/* Electricalicon animation  */

.Electricalicon {
    animation: expand 0.5s cubic-bezier(0.36, 0.07, 0.57, 0.99) infinite;
}

@keyframes expand {
    0% {
        transform: scale(1.5) translate(0, 0);
    }
    25% {
        transform: scale(1.5) translate(-5px, 5px);
    }
    50% {
        transform: scale(2) translate(0, 0);
    }
    75% {
        transform: scale(1.5) translate(5px, -5px);
    }
    100% {
        transform: scale(1.5) translate(0, 0);
    }
}


/* Financialicon animation  */

.Financialicon {
    animation: heart 0.5s cubic-bezier(0.36, 0.07, 0.57, 0.99) infinite;
}

@keyframes heart {
    0% {
        transform: scale(1.5);
    }
    20% {
        transform: scale(2);
    }
    30% {
        transform: scale(1.5);
    }
    40% {
        transform: scale(2);
    }
    100% {
        transform: scale(1.5);
    }
}


/* FMCGicon animation  */

.FMCGicon {
    animation: undo 0.5s cubic-bezier(0.36, 0.07, 0.57, 0.99) infinite;
}

@keyframes undo {
    0% {
        transform: scale(1.5) translateX(0) rotate(0);
    }
    33% {
        transform: scale(1.5) translateX(10px) rotate(0);
    }
    66% {
        transform: scale(1.5) translateX(-10px) rotate(-360deg);
    }
    100% {
        transform: scale(1.5) translateX(0) rotate(-360deg);
    }
}


/* Healthcareicon animation  */

.Healthcareicon {
    animation: italic 0.5s cubic-bezier(0.36, 0.07, 0.57, 0.99) infinite;
}

@keyframes italic {
    0% {
        transform: scale(1.5) skewX(0);
    }
    50% {
        transform: scale(1.5) skewX(12deg);
    }
    100% {
        transform: scale(1.5) skewX(0);
    }
}


/* Industrialicon animation  */

.Industrialicon {
    animation: cog 0.5s cubic-bezier(0.36, 0.07, 0.57, 0.99) infinite;
}

@keyframes cog {
    0% {
        transform: scale(1.5) rotate(0);
    }
    10% {
        transform: scale(1.5) rotate(45deg);
    }
    17% {
        transform: scale(1.5) rotate(45deg);
    }
    20% {
        transform: scale(1.5) rotate(90deg);
    }
    27% {
        transform: scale(1.5) rotate(90deg);
    }
    30% {
        transform: scale(1.5) rotate(135deg);
    }
    37% {
        transform: scale(1.5) rotate(135deg);
    }
    40% {
        transform: scale(1.5) rotate(180deg);
    }
    47% {
        transform: scale(1.5) rotate(180deg);
    }
    50% {
        transform: scale(1.5) rotate(225deg);
    }
    57% {
        transform: scale(1.5) rotate(225deg);
    }
    60% {
        transform: scale(1.5) rotate(270deg);
    }
    67% {
        transform: scale(1.5) rotate(270deg);
    }
    70% {
        transform: scale(1.5) rotate(315deg);
    }
    77% {
        transform: scale(1.5) rotate(315deg);
    }
    80% {
        transform: scale(1.5) rotate(360deg);
    }
    100% {
        transform: scale(1.5) rotate(360deg);
    }
}


/* Infrastructureicon
 animation  */

.Infrastructureicon {
    animation: wrench 0.5s cubic-bezier(0.36, 0.07, 0.57, 0.99) infinite;
}

@keyframes wrench {
    0% {
        transform: scale(1.5) rotate(0);
    }
    20% {
        transform: scale(1.5) rotate(30deg);
    }
    30% {
        transform: scale(1.5) rotate(-20deg);
    }
    50% {
        transform: scale(1.5) rotate(30deg);
    }
    60% {
        transform: scale(1.5) rotate(-20deg);
    }
    100% {
        transform: scale(1.5) rotate(0);
    }
}


/* Logisticsicon animation  */

.Logisticsicon {
    animation: mouse-pointer 0.5s cubic-bezier(0.36, 0.07, 0.57, 0.99) infinite;
}

@keyframes mouse-pointer {
    0% {
        transform: scale(1.5);
    }
    20% {
        transform: scale(1.5);
    }
    22.5% {
        transform: scale(1.2);
    }
    32.5% {
        transform: scale(1.2);
    }
    35% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1.5);
    }
}


/* Tourismicon animation  */

.Tourismicon {
    animation: cog 0.5s cubic-bezier(0.36, 0.07, 0.57, 0.99) infinite;
}

@keyframes magic {
    0% {
        transform: scale(1.5) translate(0, 0) rotate(0);
    }
    10% {
        transform: scale(1.5) translate(-5px, 2.5px) rotate(-10deg);
    }
    20% {
        transform: scale(1.5) translate(-10px, 0) rotate(-20deg);
    }
    30% {
        transform: scale(1.5) translate(-5px, -2.5px) rotate(-30deg);
    }
    40% {
        transform: scale(1.5) translate(0, 0) rotate(-20deg);
    }
    50% {
        transform: scale(1.5) translate(5px, 2.5px) rotate(-10deg);
    }
    60% {
        transform: scale(1.5) translate(10px, 0) rotate(0);
    }
    70% {
        transform: scale(1.5) translate(5px, -2.5px) rotate(10deg);
    }
    100% {
        transform: scale(1.5) translate(0, 0) rotate(0);
    }
}


/* Mediaicon animation  */

.Mediaicon {
    animation: lock 0.5s cubic-bezier(0.36, 0.07, 0.57, 0.99) infinite;
}

@keyframes lock {
    0% {
        transform: scale(1.5) translateY(0);
    }
    20% {
        transform: scale(1.5) translateY(-5px);
    }
    30% {
        transform: scale(1.5) translateY(5px);
    }
    50% {
        transform: scale(1.5) translateY(-5px);
    }
    60% {
        transform: scale(1.5) translateY(5px);
    }
    100% {
        transform: scale(1.5) translateY(0);
    }
}


/* Metalsicon animation  */

.Metalsicon {
    animation: unlock 0.5s cubic-bezier(0.36, 0.07, 0.57, 0.99) infinite;
}

@keyframes unlock {
    0% {
        transform: scale(1.5) rotate(-15deg);
    }
    15% {
        transform: scale(1.5) rotate(-40deg);
    }
    30% {
        transform: scale(1.5) rotate(5deg);
    }
    45% {
        transform: scale(1.5) rotate(-30deg);
    }
    60% {
        transform: scale(1.5) rotate(-5deg);
    }
    75% {
        transform: scale(1.5) rotate(-20deg);
    }
    90% {
        transform: scale(1.5) rotate(-15deg);
    }
    100% {
        transform: scale(1.5) rotate(-15deg);
    }
}


/* Oilicon animation  */

.Oilicon {
    animation: hourglass 0.5s cubic-bezier(0.36, 0.07, 0.57, 0.99) infinite;
}

@keyframes hourglass {
    0% {
        transform: scale(1.5) rotate(0);
    }
    35% {
        transform: scale(1.5) rotate(180deg);
    }
    65% {
        transform: scale(1.5) rotate(180deg);
    }
    100% {
        transform: scale(1.5) rotate(0);
    }
}


/* Outsourcingicon animation  */

.Outsourcingicon {
    animation: eraser 0.5s cubic-bezier(0.36, 0.07, 0.57, 0.99) infinite;
}

@keyframes eraser {
    0% {
        transform: scale(1.5) translate(0, 0);
    }
    15% {
        transform: scale(1.5) translate(-10px, -5px);
    }
    30% {
        transform: scale(1.5) translate(-10px, 5px);
    }
    45% {
        transform: scale(1.5) translate(-5px, -5px);
    }
    60% {
        transform: scale(1.5) translate(-5px, 5px);
    }
    75% {
        transform: scale(1.5) translate(0, 0);
    }
    100% {
        transform: scale(1.5) translate(10px, 0);
    }
}


/* Pharmaicon animation  */

.Pharmaicon {
    animation: times 0.5s cubic-bezier(0.36, 0.07, 0.57, 0.99) infinite;
}

@keyframes times {
    0% {
        transform: scale(1.5) translateX(0);
    }
    15% {
        transform: scale(1.5) translateX(5px);
    }
    30% {
        transform: scale(1.5) translateX(-5px);
    }
    45% {
        transform: scale(1.5) translateX(3px);
    }
    60% {
        transform: scale(1.5) translateX(-3px);
    }
    75% {
        transform: scale(1.5) translateX(0);
    }
    100% {
        transform: scale(1.5) translateX(0);
    }
}


/* Professionalicon animation  */

.Professionalicon {
    animation: wrench 0.5s cubic-bezier(0.36, 0.07, 0.57, 0.99) infinite;
}

@keyframes battery {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}


/* Realicon animation  */

.Realicon {
    animation: down 1.5s infinite;
    -webkit-animation: down 1.5s infinite;
}

@keyframes down {
    0% {
        transform: translate(0);
    }
    20% {
        transform: translateY(15px);
    }
    40% {
        transform: translate(0);
    }
}

@-webkit-keyframes down {
    0% {
        transform: translate(0);
    }
    20% {
        transform: translateY(15px);
    }
    40% {
        transform: translate(0);
    }
}

.iconmove {
    animation: slideleft 100s linear infinite;
    -webkit-animation: slideleft 100s linear infinite;
}

@keyframes slideleft {
    0% {
        background-position-x: 0;
    }
    100% {
        background-position-x: -2956px;
    }
}


/* overlay video */


/* .overlayvideo {
    background-color: rgba(255, 255, 255, 0.3);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
} */


/* moving plane animation */


/* #aeroplane{
  position: absolute;
  background-image: url('https://aepc-images.s3.ap-south-1.amazonaws.com/image/public/image2d/walstrat/helicopter.png');
  background-repeat: no-repeat;
  width: 200px;
  height: 70px;
  -webkit-animation: movingplane 10s linear infinite;
-moz-animation: movingplane 10s linear infinite;
  -o-animation: movingplane 10s linear infinite;
  z-index: 100;
}
@keyframes movingplane {
  0% { 
        margin-left: 100%;
        top:300px;
    }
    29% { 
        margin-left: 50%; 
        top: 50px; 
        -webkit-transform: rotate(20deg);
      -moz-transform: rotate(20deg);
        -ms-transform: rotate(20deg);
      transform: rotate(20deg);
    }
    31% {
        -webkit-transform: rotate(-20deg);
      -moz-transform: rotate(-20deg);
        -ms-transform: rotate(-20deg);
      transform: rotate(-20deg);
    }
    60% {
        margin-left: 0%; 
        top: 300px;    
    }
    100% {
        margin-left: -100%;
        top: 300px;
    }
}
@-webkit-keyframes movingplane {
  0% { 
        margin-left: 100%;
        top:300px;
    }
    29% { 
        margin-left: 50%; 
        top: 50px; 
        -webkit-transform: rotate(20deg);
      -moz-transform: rotate(20deg);
        -ms-transform: rotate(20deg);
      transform: rotate(20deg);
    }
    31% {
        -webkit-transform: rotate(-20deg);
      -moz-transform: rotate(-20deg);
        -ms-transform: rotate(-20deg);
      transform: rotate(-20deg);
    }
    60% {
        margin-left: 0%; 
        top :300px;    
    }
    100% {
        margin-left: -100%;
        top: 300px;
    }
}
@-moz-keyframes movingplane {
  0% { 
        margin-left: 100%;
        top:300px;
    }
    29% { 
        margin-left: 50%; 
        top: 50px; 
        -webkit-transform: rotate(20deg);
      -moz-transform: rotate(20deg);
        -ms-transform: rotate(20deg);
      transform: rotate(20deg);
    }
    31% {
        -webkit-transform: rotate(-20deg);
      -moz-transform: rotate(-20deg);
        -ms-transform: rotate(-20deg);
      transform: rotate(-20deg);
    }
    60% {
        margin-left: 0%; 
        top: 300px;    
    }
    100% {
        margin-left: -100%;
        top: 300px;
    }
} */