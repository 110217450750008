/*--------------------------------------------------------------
# How We Works
--------------------------------------------------------------*/

.overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
}

.closebtn {
    color: white;
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 1000;
    font-weight: bold;
    font-size: 25px;
    cursor: pointer;
    font-family: "JosefinSans-600", sans-serif;
}

.videoposition {
    width: 60%;
    /* height: 80%; */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
}

.videowidth {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media(max-width:1000px) {
    .videoposition {
        width: 80%;
        height: auto;
    }
}

@media(max-width:600px) {
    .videoposition {
        width: 250px;
        height: auto;
    }
}

.how-we-works {
    position: relative;
    display: block;
    /* padding: 120px 0 79px; */
    padding-top: 150px;
}

.how-we-works .section-title {
    margin-bottom: 47px;
}

.how-we-works__single-list {
    position: relative;
    display: flex;
    align-items: center;
    counter-reset: count;
}

.how-we-works__single {
    position: relative;
    display: block;
    text-align: center;
    margin-bottom: 30px;
    max-width: 390px;
    width: 100%;
    padding: 0 15px;
}

.how-we-works__icon-box {
    position: relative;
    display: block;
}

.how-we-works__icon {
    position: relative;
    height: 185px;
    width: 185px !important;
    background-color: #031163;
    border-radius: 50%;
    font-size: 65px;
    color: var(--thm-black);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    transition: all 500ms ease;
}

.how-we-works__icon_Image {
    /* height: 150px; */
    width: 64px !important;
    transition: all 500ms ease;
    /* border-radius: 50%; */
    /* font-size: 65px;
    color: var(--thm-black);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    background-color: #031163; */
    /* position: relative; */
}

.how-we-works__single:hover .how-we-works__icon {
    background-color: #05716C;
    color: #ffffff;
}

.how-we-works__icon span {}

.how-we-works__count {
    height: 67px;
    width: 67px;
    background-color: var(--thm-black);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: -10px;
    margin: 0 auto;
}

.how-we-works__count:before {
    position: absolute;
    height: 67px;
    width: 67px;
    text-align: center;
    color: #ffffff;
    font-size: 16px;
    line-height: 67px;
    border-radius: 50%;
    font-weight: 500;
    counter-increment: count;
    content: "0"counter(count);
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.how-we-works__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
    color: var(--thm-black);
    text-transform: uppercase;
    margin-top: 47px;
    margin-bottom: 28px;
    cursor: pointer;
}

.how-we-works__text {
    margin: 0;
}


/* Medium screen  */

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .how-we-works {
        /* padding: 110px 0 79px; */
    }
    /* .how-we-works__single-list {
        display: block;
    } */
    .how-we-works__single {
        max-width: 100%;
        width: 390px;
        margin-left: auto;
        margin-right: auto;
    }
    .how-we-works .section-title__title {
        font-size: 35px;
        line-height: 45px;
    }
    /* .how-we-works__single-list {
        display: block;
    } */
    .how-we-works__single {
        max-width: 100%;
        width: 390px;
        margin-left: auto;
        margin-right: auto;
    }
}


/* @media(max-width:600px){

} */