.contact {
    width: 100%;
    /* height: 330px; */
    padding: 25px;
    margin: 0 auto;
    text-align: center;
    margin-top: 40px;
    border-radius: 5px;
    box-shadow: 3px 3px 8px 0px #0e0e0e;
    background-color: rgba(255, 255, 255, 0.12);
}

.c1,
.c2,
.c3,
.c4 {
    display: inline-block;
    color: #05716C;
    height: 35px;
    font-size: 20px;
    margin: 10px 5px;
    padding: 0 0 0 10px;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.45);
    background-color: rgba(255, 255, 255, 0.28);
}

.colorinput::placeholder {
    color: #05716C;
    font-size: 18px
}

.c1,
.c2 {
    width: 45%;
}

.c3,
.c4 {
    width: 94%;
}

.c4 {
    height: 90px;
    padding: 5px 10px;
}

.contactvideo {
    height: 100vh;
    width: 100vw;
}

@media(min-width:1600px) {
    .contactvideo {
        padding-bottom: 100px;
    }

    #contactbgvideo {
        height: 100vh;
    }
}

.contactvideolayer {
    position: absolute;
}

.termstext {
    margin: 10px 5px;
    color: #05716C;
}


/* .heightlayout{
	  display: flex;
	  flex-direction: row;
  }
@media(max-width:600px){
	.heightlayout{
		height: 200px !important;
		overflow: auto !important;
		flex-direction: column;
	}
} */


/* schedule meeting section */

.schedulebtn {
    position: fixed;
    right: 0;
    bottom: 80px;
    z-index: 1000;
    transform: rotate(-90deg);
    margin-right: -62px;
    font-size: 15px;
    /* width: 150px; */
}

.schedulebtn2 {
    position: fixed;
    right: 0;
    bottom: 210px;
    z-index: 1000;
    cursor: pointer;
    /* transform: rotate( -90deg); */
    /* margin-right: -62px; */
    font-size: 15px;
    border-radius: 50%;
    width: 60px;
}

@media(max-width:600px) {
    .schedulebtn {
        margin-right: -40px;
    }

    .schedulebtn2 {
        margin-right: 5px;
        bottom: 190px;
    }
}

.titleschedule {
    font-size: 30px;
    font-family: 'Sitka', serif;
    color: #031163;
    font-weight: bold;
    margin-bottom: 0;
}

.fontschedule {
    font-size: 1.2rem !important;
}

@media(max-width:600px) {
    .titleschedule {
        font-size: 20px;
    }

    .fontschedule {
        font-size: 0.9rem !important;
    }

    #contactbgvideo {
        height: 100vh !important;
    }
}