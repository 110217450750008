.w3layouts-main {
    background-image: url(../assets/placeholders/adminlogin.jpg);
    background-repeat: repeat-x;
    animation: slideleft 100s linear infinite;
    -webkit-animation: slideleft 100s linear infinite;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-attachment: fixed;
    position: relative;
    min-height: 100vh;
}
@keyframes slideleft{
    0%{
        background-position-x: 0;    }
    100%{
        background-position-x:-2956px;
    }
}
.bg-layer{
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column
}
.header-main {
    max-width: 310px;
    margin: 0 auto;
    position: relative;
    z-index: 999;
    padding: 3em 2em;
    background: rgba(255, 255, 255, 0.04);
    -webkit-box-shadow: -1px 4px 28px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px 4px 28px 0px rgba(0,0,0,0.75);
    box-shadow: -1px 4px 28px 0px rgba(0,0,0,0.75);
}
.main-icon {
    text-align: center;
}
.main-icon span.fa {
    font-size: 50px;
    color: #fff;
    margin-bottom: 1em;
}
.icon1 {
    margin: 0 0 1em;
    padding: .8em 1em;
    background: rgba(255, 255, 255, 0.94);
}
.inputadmin {
    outline: none;
    font-size: 1.7rem;
    color: #05716C;
    border: none;
    width: 90%;
    display: inline-block;
    background: transparent;
    letter-spacing: 1px;
}
.inputadmin::placeholder{
	color: #05716C;
  }
.adminlogintext{
    color: white;
    font-size: 4rem;
    margin-top: 30px;
    margin-bottom: 30px;
}
.adminloginbutton{
    background: linear-gradient(60deg, #031163, #05716C)!important;
    color: white !important;
    border: 2px solid #fff !important;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    text-transform: uppercase !important;
    font-size: 1.7rem !important;
    line-height: 1.1 !important;
    font-weight: 600 !important;
    letter-spacing: 1px !important;
    transition: all 0.3s ease !important;
    padding: 1.3rem 2.9rem !important;
    width: 100%;
    outline: none !important;
    font-family: 'Sitka', serif !important;
}
.flexchoose{
    display: flex;
    align-items: center;
}
  
