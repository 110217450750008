@media (min-width: 1200px) {
    .article__main--width-full {
        max-width: 1830px;
    }
}

@media (min-width: 992px) {
    .article__main--width-small {
        max-width: 760px;
    }
}

.news-listing {
    list-style: none;
    padding: 0;
}

.news-listing__item {
    margin-bottom: 30px;
}

@media (min-width: 992px) {
    .news-listing__item {
        height: 393px;
    }
}

@media (min-width: 992px) {
    .news-listing__item--high {
        height: 478px;
    }
}

@media (min-width: 992px) {
    .news-listing__item--auto {
        height: auto;
    }
}


/* 4.6 Statistics block */

.card {
    position: relative;
    display: block;
    box-sizing: border-box;
}

.card--masonry .card__heading,
.card--carousel .card__heading {
    visibility: hidden;
}

.card--masonry .card__heading br,
.card--carousel .card__heading br {
    display: none;
}

.card--masonry:hover .card__heading,
.card--masonry:focus .card__heading,
.card--carousel:hover .card__heading,
.card--carousel:focus .card__heading {
    visibility: visible;
}

.card--carousel .card__heading br {
    display: block;
}

.card--carousel .card__text {
    max-width: 472px;
}

.card--small .card__heading {
    font-size: 25px;
}

.card--team .card__heading {
    visibility: hidden;
}

.card--team:focus .card__heading {
    visibility: visible;
}

.card--team:focus .card__content {
    border: solid 4px #031163;
    background-color: rgba(255, 255, 255, 0.9) !important;
}

@media (min-width: 992px) {
    .card--team:hover .card__heading,
    .card--team:focus .card__heading {
        visibility: visible;
    }
    .card--team:hover .card__content,
    .card--team:focus .card__content {
        border: solid 4px #031163;
        background-color: rgba(255, 255, 255, 0.9) !important;
    }
}

.card--team .social__link svg {
    fill: #05716C;
}

.card--team .social__link:focus svg {
    fill: #031163;
}

@media (min-width: 992px) {
    .card--team .social__link:hover svg,
    .card--team .social__link:focus svg {
        fill: #031163;
    }
}

.card--send {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 60px 40px;
    border: solid 1px #ababab;
    transition: border-color 0.3s ease;
}

.card--send:focus {
    border-color: #031163;
}

.card--send:focus .card__send-text {
    color: #031163;
}

@media (min-width: 992px) {
    .card--send:hover,
    .card--send:focus {
        border-color: #031163;
    }
    .card--send:hover .card__send-text,
    .card--send:focus .card__send-text {
        color: #031163;
    }
}

.card--transparent {
    min-height: 340px;
    border: solid 1px #ffffff;
    transition: border-color 0.3s ease;
}

.card--transparent .card__heading {
    position: static;
    font-size: 27px;
    line-height: 1.29;
}

.card--transparent .card__content {
    padding: 24px 30px 4px;
    justify-content: flex-end;
}

.card--transparent .card__text {
    position: absolute;
    margin-bottom: 20px;
}

.card--transparent:focus {
    border-color: #031163;
}

.card--transparent:focus .card__text {
    position: static;
    transition: transform 0.4s 0.4s ease, opacity 0.4s 0.4s ease;
}

@media (min-width: 992px) {
    .card--transparent:hover,
    .card--transparent:focus {
        border-color: #031163;
        transition: transform 0.4s 0.4s ease, opacity 0.4s 0.4s ease;
    }
    .card--transparent:hover .card__text,
    .card--transparent:focus .card__text {
        position: static;
    }
}

.card--active {
    border-color: #031163;
}

.card--active .card__heading {
    position: static !important;
    transform: translateY(0);
}

.card--active .card__text {
    position: static !important;
}

.card:hover,
.card:focus,
.card--active {
    outline: none;
}

.card:hover .card__content,
.card:focus .card__content,
.card--active .card__content {
    background-color: rgba(251, 199, 16, 0.95);
}

.card:hover .card__date,
.card:focus .card__date,
.card--active .card__date {
    opacity: 1;
    transform: translateY(0);
    transition: transform 0.6s 0.4s ease, opacity 0.6s 0.4s ease;
}

.card:hover .card__tag,
.card:focus .card__tag,
.card--active .card__tag {
    color: #05716C;
    transform: translateY(0);
    transition: transform 0.5s ease;
}

.card:hover .card__heading,
.card:focus .card__heading,
.card--active .card__heading {
    position: static;
    color: #05716C;
    transform: translateY(0);
    transition: transform 0.5s ease;
}

.card:hover .card__text,
.card:focus .card__text,
.card--active .card__text {
    opacity: 1;
    transform: translateY(0);
    transition: transform 0.4s 0.2s ease, opacity 0.4s 0.2s ease;
}

.card:hover .card__bottom,
.card:focus .card__bottom,
.card--active .card__bottom {
    opacity: 1;
}

.card__tag {
    margin-bottom: 4px;
    color: #ffffff;
    transform: translateY(-20px);
}

.card__send-text {
    max-width: 100px;
    font-family: "Gilroy", "Arial", sans-serif;
    font-size: 25px;
    line-height: 1.2;
    color: #05716C;
    font-weight: 700;
    text-transform: uppercase;
    transition: color 0.3s ease;
}

.card__image {
    display: block;
}

.card__image img {
    display: block;
    width: 100%;
    height: auto;
}

.card__content {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 30px;
    transition: background-color 0.5s ease;
}

@media (min-width: 1200px) {
    .card__content {
        padding: 44px 30px;
    }
}

.card__date {
    font-size: 16px;
    line-height: 26px;
    color: #05716C;
    margin-bottom: 16px;
}

@media (min-width: 992px) {
    .card__date {
        opacity: 0;
        transform: translateY(-20px);
    }
}

.card__heading {
    position: absolute;
    left: 32px;
    bottom: 12px;
    margin: 0;
    transform: translateY(-20px);
    font-size: 28px;
    line-height: 1.4;
    color: #ffffff;
    font-family: "Gilroy", "Arial", sans-serif;
}

@media (min-width: 1200px) {
    .card__heading {
        left: 40px;
        bottom: 24px;
        font-size: 32px;
    }
}

.card__text {
    display: block;
    max-width: 340px;
    margin-top: 24px;
    margin-bottom: auto;
    opacity: 0;
    transform: translateY(-20px);
    font-size: 16px;
    line-height: 1.3125;
    color: #05716C;
}

.card__text br {
    display: none;
}

@media (min-width: 992px) {
    .card__text br {
        display: block;
    }
}

.card__bottom {
    display: block;
    margin-top: 12px;
    opacity: 0;
}

.card__link {
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 1.15;
    color: #05716C;
    font-weight: 700;
}

.card__link svg {
    margin-left: 8px;
}

.card .social__link:hover svg,
.card .social__link:focus svg {
    fill: #ffffff;
}

.card--news {
    height: 100%;
    overflow: hidden;
}

.card--news:hover,
.card--news:focus {
    outline: none;
}

.card--news:hover .card__content,
.card--news:focus .card__content {
    background-color: #ffffff;
}

.card--news:hover .card__heading,
.card--news:focus .card__heading {
    visibility: visible;
}

@media (min-width: 992px) {
    .card--news:hover:hover,
    .card--news:hover:focus,
    .card--news:focus:hover,
    .card--news:focus:focus {
        outline: none;
    }
    .card--news:hover:hover .card__content,
    .card--news:hover:focus .card__content,
    .card--news:focus:hover .card__content,
    .card--news:focus:focus .card__content {
        background-color: #ffffff;
        transform: translateX(0);
    }
    .card--news:hover:hover .card__heading,
    .card--news:hover:focus .card__heading,
    .card--news:focus:hover .card__heading,
    .card--news:focus:focus .card__heading {
        opacity: 1;
        transition: transform 0.5s 0.5s ease, opacity 0.4s 0.5s ease;
    }
    .card--news:hover:hover .card__text,
    .card--news:hover:focus .card__text,
    .card--news:focus:hover .card__text,
    .card--news:focus:focus .card__text {
        transition: transform 0.4s 0.6s ease, opacity 0.4s 0.6s ease;
    }
}

.card--news .card__content {
    width: 100%;
    padding-left: 0;
    background-color: #ffffff;
    position: relative;
    transition: transform 0.4s ease;
}

@media (min-width: 992px) {
    .card--news .card__content {
        max-width: 351px;
        padding-left: 30px;
        border: 4px solid #031163;
        transform: translateX(100%);
        position: absolute;
        left: unset;
        right: 0;
    }
}

@media (min-width: 1200px) {
    .card--news .card__content {
        padding: 36px 32px 22px;
    }
}

@media (min-width: 992px) {
    .card--news .card__content--full {
        max-width: 100%;
    }
}

.card--news .card__heading {
    position: relative;
    left: unset;
    bottom: unset;
    transform: none;
    color: #05716C;
    visibility: visible;
}

@media (min-width: 992px) {
    .card--news .card__heading {
        position: absolute;
        left: 32px;
        bottom: 12px;
        opacity: 0;
        transform: translateY(-20px);
    }
}

.card--news .card__text {
    margin-top: 27px;
    line-height: 26px;
    opacity: 1;
    transform: none;
}

@media (min-width: 992px) {
    .card--news .card__text {
        opacity: 0;
        transform: translateY(-20px);
    }
}

.card--news .card__image {
    height: 400px;
    width: 100%;
}

@media (min-width: 992px) {
    .card--news .card__image {
        height: 100%;
    }
}

.card--news .card__image img {
    width: 100%;
    height: 350px;
    object-fit: cover;
}

.card--news .card__bottom {
    opacity: 1;
}

@media (min-width: 992px) {
    .card--news .card__bottom {
        opacity: 0;
    }
}

.card--news .card__link {
    font-size: 16px;
    font-weight: 600;
    color: #031163;
}

@media (min-width: 992px) {
    .card--news .card__link:hover svg,
    .card--news .card__link:focus svg {
        animation: link-arrow-hover 0.4s ease;
    }
}

.card--news .card__link svg {
    fill: #031163;
    margin-left: 16px;
}

.card--news-masonry:hover .card__content,
.card--news-masonry:focus .card__content {
    transform: none;
    border-color: #031163;
}

.card--news-masonry:hover .card__date,
.card--news-masonry:focus .card__date {
    transform: none;
}

.card--news-masonry:hover .card__heading,
.card--news-masonry:focus .card__heading {
    transform: none;
}

.card--news-masonry:hover .card__text,
.card--news-masonry:focus .card__text {
    transform: none;
}

.card--news-masonry:hover .card__link,
.card--news-masonry:focus .card__link {
    color: #031163;
}

.card--news-masonry:hover .card__link svg,
.card--news-masonry:focus .card__link svg {
    fill: #031163;
}

.card--news-masonry .card__content {
    border-color: transparent;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

@media (min-width: 992px) {
    .card--news-masonry .card__content {
        background-color: #f4f4f4;
        transform: none;
        position: relative;
    }
}

@media (min-width: 992px) {
    .card--news-masonry .card__date {
        opacity: 1;
        transform: none;
    }
}

@media (min-width: 992px) {
    .card--news-masonry .card__heading {
        position: relative;
        left: unset;
        bottom: unset;
        transform: none;
        opacity: 1;
    }
}

@media (min-width: 992px) {
    .card--news-masonry .card__text {
        opacity: 1;
        transform: none;
    }
}

.card--news-masonry .card__bottom {
    opacity: 1;
}

.card--news-masonry .card__link {
    color: #05716C;
    transition: color 0.3s ease;
}

.card--news-masonry .card__link svg {
    fill: #05716C;
    transition: fill 0.3s ease;
}