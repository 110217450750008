.padding-blog {
  padding-top: 180px;
}

.margintopadmin {
  margin-top: 20px;
}

/* video section */

#video-viewadmin {
  top: 17;
  left: 0;
  width: 100%;
  height: fit-content;
  overflow: hidden;
  z-index: 1;
  margin-top: 100px;
  transition: 0.7s ease;
  position: absolute;
}

#video-viewadmin::after {
  content: "";
  background-color: rgba(5, 113, 108, 0.2);
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;
}

.video-js.vjs-fluid,
.video-js.vjs-16-9,
.video-js.vjs-4-3 {
  height: 100% !important;
}

/* form section */

.header-mainadmin {
  max-width: 910px;
  margin: 0 auto;
  position: relative;
  z-index: 999;
  padding: 3em 2em;
  background: rgba(255, 255, 255, 0.04);
  -webkit-box-shadow: -1px 4px 28px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 4px 28px 0px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 4px 28px 0px rgba(0, 0, 0, 0.75);
}

.marginrightpublish {
  margin-right: 20px;
}

input[type="file"] {
  outline: none;
  font-size: 1.7rem;
  color: #05716c;
  border: none;
  width: 90%;
  display: inline-block;
  background: transparent;
  letter-spacing: 1px;
}
.box select {
  outline: none;
  font-size: 1.7rem;
  color: #05716c;
  border: none;
  width: 90%;
  display: inline-block;
  background: transparent;
  letter-spacing: 1px;
  width: 100%;
}
.box {
  margin: 0 0 1em;
  padding: 0.8em 1em;
  background: rgba(255, 255, 255, 0.94);
}
.serviceheader {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 1s ease;
  background: linear-gradient(60deg, #031163, #05716c) !important;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.positionlogo {
  /* position: fixed; */
  z-index: 10;
  top: 10px;
  left: 10px;
  cursor: pointer;
}
.backtohomebtn {
  background: linear-gradient(60deg, #031163, #05716c) !important;
  color: white !important;
  border: 2px solid #fff !important;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-transform: uppercase !important;
  font-size: 1.7rem !important;
  line-height: 1.1 !important;
  font-weight: 600 !important;
  letter-spacing: 1px !important;
  transition: all 0.3s ease !important;
  padding: 1.3rem 2.9rem !important;
  /* width: 100%; */
  outline: none !important;
  font-family: "Sitka", serif !important;
}

@media (max-width: 600px) {
  .backtohomebtn {
    font-size: 1.2rem !important;
  }
}

.footer {
  position: absolute;
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: all 1s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
