.section {
    position: 'relative ';
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-self: center;
    overflow: hidden;
}

.video-container {
    position: absolute;
    top: 100;
    left: 0;
    width: 100%;

}