.widthlogo {
    width: 100px;
}

.widthlogoslider {
    width: 200px;
}

@media (max-width: 600px) {
    .widthlogoslider {
        width: 80px;
    }
}

.Button_button__mejG9 {
    background: linear-gradient(60deg, #031163, #05716c) !important;
    color: white !important;
    border: 2px solid #fff !important;
}


/* .TextBlock_info__1I1Jw .TextBlock_subtitle__2Zinx {
    font-size: 3.5em !important;
} */

.TextBlock_image__2bOzx .TextBlock_photo__2N3jz span:before {
    background-image: linear-gradient(60deg, #031163, #05716c) !important;
}

.TextBlock_info__1I1Jw h2,
.TextBlock_paragraph__1PFJ3,
.Contacts2_header__1-MQ9 h2,
.Contacts2_header__1-MQ9 p,
.layout-header h2,
.layout-header p,
.BlogCard_card__ziaOW h3,
.BlogCard_card__ziaOW p {
    color: #05716c !important;
}

.Layout_outer__RE_Ht.Layout_dark__1ypk6 {
    box-shadow: inset 0 0 0 1000px rgba(3, 22, 99, 0.4) !important;
}

.Header_sticky__UGgtg {
    background: linear-gradient(60deg, #031163, #05716c) !important;
}

.Header_menu__CZvFM>li>a,
.Header_menu__CZvFM>li span>a {
    font-size: 1.5rem;
    font-weight: 600;
}


/* contact color */

.AddressBox_address_box__Egezm {
    background: linear-gradient(60deg, #031163, #05716c) !important;
}

.AddressBox_address_box__Egezm i,
.CardImage_wrapper__QFK-L figcaption,
.BlogCategories_categories__2MObE h3 {
    color: white !important;
}

.AddressBox_address_box__Egezm h3 {
    color: #fff !important;
}

.AddressBox_address_box__Egezm span {
    color: #fff !important;
}

.bgservicecolor {
    background-color: linear-gradient(60deg, #031163, #05716c) !important;
}

.Sidebar_menu__13xzG a {
    color: #031163 !important;
}

.BlogCard_card__ziaOW .BlogCard_extra__13HuY {
    display: none;
}

.card1Image_wrapper__QFK-L figcaption {
    color: white;
}

.CardImage_wrapper__QFK-L {
    background: linear-gradient(60deg, #031163, #05716c) !important;
}

.Slider_intro__5k5ed h1 {
    font-weight: bold !important;
}


/* services section */

section {
    /* padding: 60px 0; */
    /* min-height: 100vh;*/
}


/* .section-padding {
    padding: 120px 0;
} */

.section-title {
    margin-bottom: 50px;
}

.section-title h2 {
    font-size: 40px;
    line-height: 45px;
    position: relative;
    margin-bottom: 20px;
    color: #333333;
    text-transform: capitalize;
}

.services-area {
    margin-bottom: -30px;
    /* background: linear-gradient(60deg, rgba(3, 22, 99, 0.3), rgba(5, 113, 99, 0.5)) !important; */
}

.serviecs-block .single-serviecs-block {
    margin: 30px 0;
}

.single-serviecs-block {
    padding: 30px 40px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    text-align: center;
    overflow: hidden;
    position: relative;
    display: block;
    height: 320px;
    margin-bottom: 30px;
}

.single-serviecs-block:hover h3 a {
    color: #fff;
    margin-top: 180px;
}

.single-serviecs-block:hover p {
    display: none;
}

.single-serviecs-block:hover i {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -90%);
    background: #fff;
    color: #031163;
    height: 100px;
    width: 100px;
    font-size: 40px;
    line-height: 100px;
}

.single-serviecs-block h3 a {
    color: #333333;
    margin-bottom: 15px;
    margin-top: 80px;
    display: block;
    overflow: hidden;
    transition: 0.5s;
}

.single-serviecs-block i {
    color: #fff;
    font-size: 50px;
    height: 140px;
    width: 140px;
    line-height: 170px;
    text-align: center;
    border-radius: 50%;
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    transition: 0.6s;
}

.firstblock i {
    background: linear-gradient(60deg, #031163, #05716c);
}

.firstblock:hover {
    background: linear-gradient(60deg, #031163, #05716c);
    color: #fff;
}

.firstblock:hover .firstblockimg {
    content: url("../assets/placeholders/services/green.png");
}

.text1color {
    color: #031163 !important;
}

.firstblock:hover .text1color {
    color: #fff !important;
}

.secondblock i {
    background: linear-gradient(60deg, #358597, #f4a896);
}

.secondblock:hover {
    background: linear-gradient(60deg, #358597, #f4a896);
    color: #fff;
}

.secondblock:hover .secondblockimg {
    content: url("../assets/placeholders/services/Manufacture.png");
}

.text2color {
    color: #358597 !important;
}

.secondblock:hover .text2color {
    color: #fff !important;
}

.thirdblock i {
    background: linear-gradient(60deg, #4a73ad, #643b7a);
}

.thirdblock:hover {
    background: linear-gradient(60deg, #4a73ad, #643b7a);
    color: #fff;
}

.thirdblock:hover .thirdblockimg {
    content: url("../assets/placeholders/services/logistics.png");
}

.text3color {
    color: #4a73ad !important;
}

.thirdblock:hover .text3color {
    color: #fff !important;
}

.text3color:hover {
    color: #fff !important;
}


/* services animation 3 */


/* 
    ================================
        Best Viewed In Full Page
    ================================
*/


/* Hover over a card to flip, can tab too. */

@import url("https://fonts.googleapis.com/css?family=Lato");

/* default */

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


/* body */


/* .flip-card-container */

.flip-card-container {
    --hue: 150;
    --primary: hsl(var(--hue), 50%, 50%);
    --white-1: hsl(0, 0%, 90%);
    --white-2: hsl(0, 0%, 80%);
    --dark: hsl(var(--hue), 25%, 10%);
    --grey: hsl(0, 0%, 50%);
    width: 100%;
    height: 300px;
    margin: 20px 0;
    perspective: 1000px;
}

@media (max-width: 600px) {
    .flip-card-container {
        height: 250px;
    }
}


/* .flip-card-container */

@media (max-width: 600px) {
    .flip-card-containerproduct {
        height: 300px !important;
    }
}


/* .flip-card */

.flip-card {
    width: inherit;
    height: inherit;
    position: relative;
    transform-style: preserve-3d;
    transition: 0.6s 0.1s;
    /* box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%); */
}

.flipcardshadow {
    box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%) !important;
}


/* hover and focus-within states */

.flip-card-container:hover .flip-card,
.flip-card-container:focus-within .flip-card {
    transform: rotateY(180deg);
}


/* .card-... */

.radiuszero {
    border-radius: 0 !important;
}

.card-front,
.card-back {
    width: 100%;
    height: 100%;
    /* border-radius: 24px;
    background:white ; */
    border-radius: 24px;
    background: var(--dark);
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-front::before,
.card-back::before {
    content: "";
    position: absolute;
    left: 0rem;
    top: 0%;
    /* background: linear-gradient(60deg, rgba(3, 22, 99, 0.2), rgba(5, 113, 99, 0.2)); */
    width: 100%;
    height: 100%;
}

.overlay1:before {
    background: linear-gradient( rgba(248, 144, 241, 0.7), rgba(65, 196, 206, 0.7));
}

.overlay2:before {
    background: linear-gradient( rgba(254, 220, 219, 0.7), rgba(212, 72, 109, 0.7));
}

.overlay3:before {
    background: linear-gradient(rgba(0, 171, 78, 0.7), rgba(32, 167, 219, 0.7));
}

.overlay4:before {
    background: linear-gradient(rgba(3, 22, 99, 0.7), rgba(5, 113, 99, 0.7));
}

.overlay5:before {
    background: linear-gradient(rgba(90, 1, 143, 0.7), rgba(255, 84, 228, 0.7));
}

.overlay6:before {
    background: linear-gradient(rgba(37, 14, 43, 0.7), rgba(4, 198, 220, 0.7));
}

.overlay7:before {
    background: linear-gradient(rgba(107, 212, 104, 0.7), rgba(4, 198, 220, 0.7));
}

.overlay8:before {
    background: linear-gradient(rgba(0, 128, 201, 0.7), rgba(0, 169, 6, 0.7));
}

.overlay9:before {
    background: linear-gradient(rgba(134, 66, 173, 0.7), rgba(2, 221, 179, 0.7));
}

.overlay10:before {
    background: linear-gradient(rgba(1, 61, 72, 0.7), rgba(125, 220, 216, 0.7));
}

.overlay11:before {
    background: linear-gradient( rgba(94, 214, 145, 0.7), rgba(185, 228, 218, 0.7));
}

.overlay15:before {
    background: linear-gradient( rgba(87, 221, 143, 0.7), rgba(247, 218, 220, 0.7));
}

.overlay13:before {
    background: linear-gradient(rgba(0, 99, 120, 0.7), rgba(140, 11, 140, 0.7));
}

.overlay14:before {
    background: linear-gradient(rgba(232, 167, 193, 0.7), rgba(2, 45, 0, 0.7));
}

.overlay12:before {
    background: linear-gradient(rgba(31, 43, 87, 0.7), rgba(255, 171, 184, 0.7));
}

.overlay16:before {
    background: linear-gradient(rgba(110, 16, 165, 0.7), rgba(241, 85, 218, 0.7));
}

.overlay17:before {
    background: linear-gradient( rgba(122, 92, 156, 0.7), rgba(249, 206, 164, 0.7));
}

.overlay18:before {
    background: linear-gradient( rgba(254, 205, 113, 0.7), rgba(229, 137, 158, 0.7));
}

.overlay19:before {
    background: linear-gradient( rgba(249, 225, 225, 0.7), rgba(40, 106, 119, 0.7));
}

.overlay20:before {
    background: linear-gradient( rgba(72, 123, 166, 0.7), rgba(199, 144, 165, 0.7));
}

.overlay21:before {
    background: linear-gradient( rgba(231, 143, 148, 0.7), rgba(178, 225, 212, 0.7));
}

.overlay22:before {
    background: linear-gradient(rgba(201, 111, 60, 0.7), rgba(245, 181, 59, 0.7));
}

.overlay23:before {
    background: linear-gradient( rgba(125, 163, 100, 0.7), rgba(232, 207, 123, 0.7));
}

.overlay24:before {
    background: linear-gradient(rgba(1, 52, 94, 0.7), rgba(59, 136, 151, 0.7));
}

.overlay25:before {
    background: linear-gradient(rgba(156, 7, 243, 0.7), rgba(224, 139, 212, 0.7));
}


/* .card-front */

.card-front {
    transform: rotateY(0deg);
    z-index: 2;
}


/* .card-back */

.card-back {
    transform: rotateY(180deg);
    z-index: 1;
}


/* figure */

.Figure {
    z-index: -1;
}


/* figure, .img-bg */

.Figure,
.img-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


/* img */

.Img {
    height: 100%;
    border-radius: 24px;
}

.widthImg {
    width: 100%;
}


/* figcaption */

:root {
    --hue: 220;
}

.Figcaption {
    display: block;
    width: 100%;
    /* margin-top: 20%; */
    padding: 8px 2px;
    font-weight: bold;
    line-height: 1.6;
    letter-spacing: 2px;
    word-spacing: 6px;
    text-align: center;
    position: absolute;
    /* top: 0; */
    /* right: 12px; */
    color: var(--white-1);
    background: linear-gradient( to right, rgba(3, 17, 99, 0.6) 0%, rgba(5, 113, 108, 0.6) 100%);
    font-size: 1.6rem;
    top: 40px;
}

@media (max-width: 600px) {
    .Figcaption {
        font-size: 0.9rem;
    }
}

.bottomtext {
    bottom: 20px;
    top: auto;
}


/* .positionfooter {
    bottom: 0;
} */

.extraFigcaption {
    display: flex !important;
    justify-content: center;
    align-items: center;
    /* top: 50px; */
}

.overlay1position {
    position: absolute;
    top: 0;
    background-image: linear-gradient( rgba(248, 144, 241, 0.8), rgba(65, 196, 206, 0.8));
    width: 100%;
    height: 50px;
}


/* .img-bg */

.img-bg {
    background: hsla(var(--hue), 25%, 10%, 0.5);
}

.card-front .img-bg {
    clip-path: polygon(0 20%, 100% 40%, 100% 100%, 0 100%);
}

.card-front .img-bg::before {
    content: "";
    position: absolute;
    top: 34%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(18deg);
    width: 100%;
    height: 6px;
    border: 1px solid var(--primary);
    border-left-color: transparent;
    border-right-color: transparent;
    transition: 0.1s;
}

.card-back .img-bg {
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0 60%);
}


/* hover state */

.flip-card-container:hover .card-front .img-bg::before {
    width: 6px;
    border-left-color: var(--primary);
    border-right-color: var(--primary);
}


/* ul */

.detailul {
    /* padding-top: 50%; */
    margin: 0 auto;
    width: 100%;
    height: 100%;
    list-style: none;
    color: var(--white-1);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    z-index: 1;
    padding: 5px;
}

@media (max-width: 600px) {
    .detailul {
        width: 100%;
    }
}


/* li */

.detailli {
    width: 100%;
    margin-top: 12px;
    padding-bottom: 12px;
    font-size: 15px;
    text-align: center;
    position: relative;
}

.detailli:nth-child(2n) {
    color: var(--white-2);
}

.detailli:not(:last-child)::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: currentColor;
    opacity: 0.2;
}


/* button */

.servicesbutton {
    font-family: "Sitka", serif;
    font-weight: bold;
    color: #031163;
    letter-spacing: 2px;
    padding: 9px 10px;
    border: 1px solid #031163;
    border-radius: 1000px;
    background: white;
    transition: 0.3s;
    cursor: pointer !important;
    position: absolute;
    z-index: 2;
    font-size: 1.6rem;
    top: 0;
    margin-top: 12%;
    width: 90%;
}


/* .servicesbutton:hover, button:focus {
    color: #fff;
    background: transparent;
    border: 1px solid #fff;
} */

.servicesbutton :active {
    transform: translate(2px);
}

.btn-glow {
    color: #fff !important;
    display: flex;
    background: rgb(3, 17, 99);
    background: -moz-linear-gradient( left, rgba(3, 17, 99, 1) 0%, rgba(5, 113, 108, 1) 100%);
    background: -webkit-linear-gradient( left, rgba(3, 17, 99, 1) 0%, rgba(5, 113, 108, 1) 100%);
    background: linear-gradient( to right, rgba(3, 17, 99, 1) 0%, rgba(5, 113, 108, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#8a61f8', endColorstr='#ec5fe7', GradientType=1);
    border: none;
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    /* line-height: 56px; */
    letter-spacing: 0.07em;
    text-decoration: none !important;
    text-transform: uppercase;
    padding: 10px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    box-shadow: none;
    animation: glow 3s infinite;
    animation-timing-function: ease-out;
    position: relative;
    border-radius: 10px;
    margin-top: 5px;
    outline: none !important;
    width: 180px;
    justify-content: center;
    /* flex-direction: column; */
}

.btn-glow:hover {
    box-shadow: 0 0 0 10px #3daaa200;
    transition: 0.8s;
    -webkit-transition: 0.8s;
}

@keyframes glow {
    0% {
        box-shadow: 0 0 20px rgb(80, 159, 230);
        text-shadow: 0 0 0px #fff;
    }
    40% {
        box-shadow: 0 0 100px rgb(80, 159, 230);
        text-shadow: 0 0 20px #fff;
    }
    100% {
        box-shadow: 0 0 20px rgb(80, 159, 230);
        text-shadow: 0 0 1px #fff;
    }
}

@media(max-width:600px) {
    .btn-glow {
        width: 130px;
        font-size: 9px;
    }
}

.btn-hover-shine {
    position: relative;
}

.btn-hover-shine:after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    height: 100%;
    right: 80%;
    width: 1px;
    background-color: #ffffff00;
    box-shadow: 0 0 20px #ffd2fe00, 0 0 10px #ffd2fe00, 0 0 5px #ffd2fe00, 0 0 2px #ffd2fe00;
    opacity: 1;
}

.btn-hover-shine:hover:after {
    right: 2%;
    background-color: #ffffffff;
    box-shadow: 0 0 30px #ffd2feff, 0 0 15px #ffd2feff, 0 0 7px #ffd2feff, 0 0 3px #ffd2feff;
    opacity: 0;
    transition: 0.6s;
    -webkit-transition: 0.6s;
}


/* .design-container */

.design-container {
    --tr: 90;
    --op: 0.5;
    width: 100%;
    height: 100%;
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
}


/* .design */

.design {
    display: block;
    background: var(--grey);
    position: absolute;
    opacity: var(--op);
    transition: 0.3s;
}

.design--1,
.design--2,
.design--3,
.design--4 {
    width: 1px;
    height: 100%;
}

.design--1,
.design--2 {
    top: 0;
    transform: translateY(calc((var(--tr) - (var(--tr) * 2)) * 1%));
}

.design--1 {
    left: 20%;
}

.design--2 {
    left: 80%;
}

.design--3,
.design--4 {
    bottom: 0;
    transform: translateY(calc((var(--tr) + (var(--tr) - var(--tr))) * 1%));
}

.design--3 {
    left: 24%;
}

.design--4 {
    left: 76%;
}

.design--5,
.design--6,
.design--7,
.design--8 {
    width: 100%;
    height: 1px;
}

.design--5,
.design--6 {
    left: 0;
    transform: translateX(calc((var(--tr) - (var(--tr) * 2)) * 1%));
}

.design--5 {
    top: 41%;
}

.design--6 {
    top: 59%;
}

.design--7,
.design--8 {
    right: 0;
    transform: translateX(calc((var(--tr) + (var(--tr) - var(--tr))) * 1%));
}

.design--7 {
    top: 44%;
}

.design--8 {
    top: 56%;
}


/* states */

.servicesbutton :hover+.design-container,
.servicesbutton :active+.design-container,
.servicesbutton :focus+.design-container {
    --tr: 20;
    --op: 0.7;
}

.abs-site-link {
    position: fixed;
    bottom: 20px;
    left: 20px;
    color: hsla(0, 0%, 100%, 0.6);
    font-size: 16px;
    font-family: "Sitka", serif;
}


/* service animation 4 */

@import url(https://fonts.googleapis.com/css?family=Open+Sans:300);

/* html {
    height: 100%;
}

body {
    text-align: center;
    height: 100%;
    background-color: #2c4159;
    font-family: 'Sitka', serif;
}

body:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
} */

.reflection-container {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    transform-style: preserve-3d;
    perspective: 1000px;
    width: 100%;
    transform: rotate(45deg);
}


/* .reflection-container:before {
    content: '';
    color: #fff;
    position: absolute;
    left: 0rem;
    top: 0%;
    transform: translateX(-100%) translateY(-50%);
    background: linear-gradient(60deg, #031163, #05716C);
    width: 100%;
    height: 100%;
} */

.reflection-container .reflection-content {
    height: 180px;
    width: 180px;
    /* background: #000 url(https://unsplash.it/800/800/?random); */
    background-size: cover;
    background-position: center;
    transform: rotateX(0) rotateY(0);
    pointer-events: none;
    transition: 100ms linear transform;
    overflow: hidden;
    /* transform: rotate(45deg); */
}

.reflection-container .overlay1 {
    background: #000 url("../assets/placeholders/automobile.jpg");
}

.reflection-container .overlay2 {
    background: #000 url("../assets/placeholders/education.jpg");
}

.reflection-container .overlay3 {
    background: #000 url("../assets/placeholders/aviation.jpg");
}

.reflection-container .overlay4 {
    background: #000 url("../assets/placeholders/industrial.jpg");
}


/* .reflection-container .overlay5 {
    background: #000 url(https://unsplash.it/800/800/?random);
} */

.reflection-container .reflection-content:before {
    content: "";
    position: absolute;
    width: 200%;
    height: 200%;
    left: -50%;
    top: -50%;
    /* background: linear-gradient(rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.2)); */
}

.reflection-container .overlay1:before {
    background: linear-gradient( rgba(207, 124, 201, 0.7), rgba(65, 196, 206, 0.7));
}

.reflection-container .overlay2:before {
    background: linear-gradient( rgba(254, 220, 219, 0.7), rgba(212, 72, 109, 0.7));
}

.reflection-container .overlay3:before {
    background: linear-gradient(rgba(0, 171, 78, 0.7), rgba(32, 167, 219, 0.7));
}

.reflection-container .overlay4:before {
    background: linear-gradient(rgba(3, 22, 99, 0.7), rgba(5, 113, 99, 0.7));
}

.reflection-container .reflection-grid-cell {
    position: absolute;
    z-index: 1;
    width: 10%;
    height: 10%;
}

.reflection-container .reflection-grid-cell-1 {
    top: 0%;
    left: 0%;
}

.reflection-container .reflection-grid-cell-1:hover~.reflection-content {
    transform: rotateX(20deg) rotateY(-20deg);
}

.reflection-container .reflection-grid-cell-1:hover~.reflection-content:before {
    transform: translateY(20%);
}

.reflection-container .reflection-grid-cell-2 {
    top: 0%;
    left: 10%;
}

.reflection-container .reflection-grid-cell-2:hover~.reflection-content {
    transform: rotateX(20deg) rotateY(-15deg);
}

.reflection-container .reflection-grid-cell-2:hover~.reflection-content:before {
    transform: translateY(20%);
}

.reflection-container .reflection-grid-cell-3 {
    top: 0%;
    left: 20%;
}

.reflection-container .reflection-grid-cell-3:hover~.reflection-content {
    transform: rotateX(20deg) rotateY(-10deg);
}

.reflection-container .reflection-grid-cell-3:hover~.reflection-content:before {
    transform: translateY(20%);
}

.reflection-container .reflection-grid-cell-4 {
    top: 0%;
    left: 30%;
}

.reflection-container .reflection-grid-cell-4:hover~.reflection-content {
    transform: rotateX(20deg) rotateY(-5deg);
}

.reflection-container .reflection-grid-cell-4:hover~.reflection-content:before {
    transform: translateY(20%);
}

.reflection-container .reflection-grid-cell-5 {
    top: 0%;
    left: 40%;
}

.reflection-container .reflection-grid-cell-5:hover~.reflection-content {
    transform: rotateX(20deg) rotateY(0deg);
}

.reflection-container .reflection-grid-cell-5:hover~.reflection-content:before {
    transform: translateY(20%);
}

.reflection-container .reflection-grid-cell-6 {
    top: 0%;
    left: 50%;
}

.reflection-container .reflection-grid-cell-6:hover~.reflection-content {
    transform: rotateX(20deg) rotateY(5deg);
}

.reflection-container .reflection-grid-cell-6:hover~.reflection-content:before {
    transform: translateY(20%);
}

.reflection-container .reflection-grid-cell-7 {
    top: 0%;
    left: 60%;
}

.reflection-container .reflection-grid-cell-7:hover~.reflection-content {
    transform: rotateX(20deg) rotateY(10deg);
}

.reflection-container .reflection-grid-cell-7:hover~.reflection-content:before {
    transform: translateY(20%);
}

.reflection-container .reflection-grid-cell-8 {
    top: 0%;
    left: 70%;
}

.reflection-container .reflection-grid-cell-8:hover~.reflection-content {
    transform: rotateX(20deg) rotateY(15deg);
}

.reflection-container .reflection-grid-cell-8:hover~.reflection-content:before {
    transform: translateY(20%);
}

.reflection-container .reflection-grid-cell-9 {
    top: 0%;
    left: 80%;
}

.reflection-container .reflection-grid-cell-9:hover~.reflection-content {
    transform: rotateX(20deg) rotateY(20deg);
}

.reflection-container .reflection-grid-cell-9:hover~.reflection-content:before {
    transform: translateY(20%);
}

.reflection-container .reflection-grid-cell-10 {
    top: 0%;
    left: 90%;
}

.reflection-container .reflection-grid-cell-10:hover~.reflection-content {
    transform: rotateX(20deg) rotateY(25deg);
}

.reflection-container .reflection-grid-cell-10:hover~.reflection-content:before {
    transform: translateY(20%);
}

.reflection-container .reflection-grid-cell-11 {
    top: 10%;
    left: 0%;
}

.reflection-container .reflection-grid-cell-11:hover~.reflection-content {
    transform: rotateX(15deg) rotateY(-20deg);
}

.reflection-container .reflection-grid-cell-11:hover~.reflection-content:before {
    transform: translateY(15%);
}

.reflection-container .reflection-grid-cell-12 {
    top: 10%;
    left: 10%;
}

.reflection-container .reflection-grid-cell-12:hover~.reflection-content {
    transform: rotateX(15deg) rotateY(-15deg);
}

.reflection-container .reflection-grid-cell-12:hover~.reflection-content:before {
    transform: translateY(15%);
}

.reflection-container .reflection-grid-cell-13 {
    top: 10%;
    left: 20%;
}

.reflection-container .reflection-grid-cell-13:hover~.reflection-content {
    transform: rotateX(15deg) rotateY(-10deg);
}

.reflection-container .reflection-grid-cell-13:hover~.reflection-content:before {
    transform: translateY(15%);
}

.reflection-container .reflection-grid-cell-14 {
    top: 10%;
    left: 30%;
}

.reflection-container .reflection-grid-cell-14:hover~.reflection-content {
    transform: rotateX(15deg) rotateY(-5deg);
}

.reflection-container .reflection-grid-cell-14:hover~.reflection-content:before {
    transform: translateY(15%);
}

.reflection-container .reflection-grid-cell-15 {
    top: 10%;
    left: 40%;
}

.reflection-container .reflection-grid-cell-15:hover~.reflection-content {
    transform: rotateX(15deg) rotateY(0deg);
}

.reflection-container .reflection-grid-cell-15:hover~.reflection-content:before {
    transform: translateY(15%);
}

.reflection-container .reflection-grid-cell-16 {
    top: 10%;
    left: 50%;
}

.reflection-container .reflection-grid-cell-16:hover~.reflection-content {
    transform: rotateX(15deg) rotateY(5deg);
}

.reflection-container .reflection-grid-cell-16:hover~.reflection-content:before {
    transform: translateY(15%);
}

.reflection-container .reflection-grid-cell-17 {
    top: 10%;
    left: 60%;
}

.reflection-container .reflection-grid-cell-17:hover~.reflection-content {
    transform: rotateX(15deg) rotateY(10deg);
}

.reflection-container .reflection-grid-cell-17:hover~.reflection-content:before {
    transform: translateY(15%);
}

.reflection-container .reflection-grid-cell-18 {
    top: 10%;
    left: 70%;
}

.reflection-container .reflection-grid-cell-18:hover~.reflection-content {
    transform: rotateX(15deg) rotateY(15deg);
}

.reflection-container .reflection-grid-cell-18:hover~.reflection-content:before {
    transform: translateY(15%);
}

.reflection-container .reflection-grid-cell-19 {
    top: 10%;
    left: 80%;
}

.reflection-container .reflection-grid-cell-19:hover~.reflection-content {
    transform: rotateX(15deg) rotateY(20deg);
}

.reflection-container .reflection-grid-cell-19:hover~.reflection-content:before {
    transform: translateY(15%);
}

.reflection-container .reflection-grid-cell-20 {
    top: 10%;
    left: 90%;
}

.reflection-container .reflection-grid-cell-20:hover~.reflection-content {
    transform: rotateX(15deg) rotateY(25deg);
}

.reflection-container .reflection-grid-cell-20:hover~.reflection-content:before {
    transform: translateY(15%);
}

.reflection-container .reflection-grid-cell-21 {
    top: 20%;
    left: 0%;
}

.reflection-container .reflection-grid-cell-21:hover~.reflection-content {
    transform: rotateX(10deg) rotateY(-20deg);
}

.reflection-container .reflection-grid-cell-21:hover~.reflection-content:before {
    transform: translateY(10%);
}

.reflection-container .reflection-grid-cell-22 {
    top: 20%;
    left: 10%;
}

.reflection-container .reflection-grid-cell-22:hover~.reflection-content {
    transform: rotateX(10deg) rotateY(-15deg);
}

.reflection-container .reflection-grid-cell-22:hover~.reflection-content:before {
    transform: translateY(10%);
}

.reflection-container .reflection-grid-cell-23 {
    top: 20%;
    left: 20%;
}

.reflection-container .reflection-grid-cell-23:hover~.reflection-content {
    transform: rotateX(10deg) rotateY(-10deg);
}

.reflection-container .reflection-grid-cell-23:hover~.reflection-content:before {
    transform: translateY(10%);
}

.reflection-container .reflection-grid-cell-24 {
    top: 20%;
    left: 30%;
}

.reflection-container .reflection-grid-cell-24:hover~.reflection-content {
    transform: rotateX(10deg) rotateY(-5deg);
}

.reflection-container .reflection-grid-cell-24:hover~.reflection-content:before {
    transform: translateY(10%);
}

.reflection-container .reflection-grid-cell-25 {
    top: 20%;
    left: 40%;
}

.reflection-container .reflection-grid-cell-25:hover~.reflection-content {
    transform: rotateX(10deg) rotateY(0deg);
}

.reflection-container .reflection-grid-cell-25:hover~.reflection-content:before {
    transform: translateY(10%);
}

.reflection-container .reflection-grid-cell-26 {
    top: 20%;
    left: 50%;
}

.reflection-container .reflection-grid-cell-26:hover~.reflection-content {
    transform: rotateX(10deg) rotateY(5deg);
}

.reflection-container .reflection-grid-cell-26:hover~.reflection-content:before {
    transform: translateY(10%);
}

.reflection-container .reflection-grid-cell-27 {
    top: 20%;
    left: 60%;
}

.reflection-container .reflection-grid-cell-27:hover~.reflection-content {
    transform: rotateX(10deg) rotateY(10deg);
}

.reflection-container .reflection-grid-cell-27:hover~.reflection-content:before {
    transform: translateY(10%);
}

.reflection-container .reflection-grid-cell-28 {
    top: 20%;
    left: 70%;
}

.reflection-container .reflection-grid-cell-28:hover~.reflection-content {
    transform: rotateX(10deg) rotateY(15deg);
}

.reflection-container .reflection-grid-cell-28:hover~.reflection-content:before {
    transform: translateY(10%);
}

.reflection-container .reflection-grid-cell-29 {
    top: 20%;
    left: 80%;
}

.reflection-container .reflection-grid-cell-29:hover~.reflection-content {
    transform: rotateX(10deg) rotateY(20deg);
}

.reflection-container .reflection-grid-cell-29:hover~.reflection-content:before {
    transform: translateY(10%);
}

.reflection-container .reflection-grid-cell-30 {
    top: 20%;
    left: 90%;
}

.reflection-container .reflection-grid-cell-30:hover~.reflection-content {
    transform: rotateX(10deg) rotateY(25deg);
}

.reflection-container .reflection-grid-cell-30:hover~.reflection-content:before {
    transform: translateY(10%);
}

.reflection-container .reflection-grid-cell-31 {
    top: 30%;
    left: 0%;
}

.reflection-container .reflection-grid-cell-31:hover~.reflection-content {
    transform: rotateX(5deg) rotateY(-20deg);
}

.reflection-container .reflection-grid-cell-31:hover~.reflection-content:before {
    transform: translateY(5%);
}

.reflection-container .reflection-grid-cell-32 {
    top: 30%;
    left: 10%;
}

.reflection-container .reflection-grid-cell-32:hover~.reflection-content {
    transform: rotateX(5deg) rotateY(-15deg);
}

.reflection-container .reflection-grid-cell-32:hover~.reflection-content:before {
    transform: translateY(5%);
}

.reflection-container .reflection-grid-cell-33 {
    top: 30%;
    left: 20%;
}

.reflection-container .reflection-grid-cell-33:hover~.reflection-content {
    transform: rotateX(5deg) rotateY(-10deg);
}

.reflection-container .reflection-grid-cell-33:hover~.reflection-content:before {
    transform: translateY(5%);
}

.reflection-container .reflection-grid-cell-34 {
    top: 30%;
    left: 30%;
}

.reflection-container .reflection-grid-cell-34:hover~.reflection-content {
    transform: rotateX(5deg) rotateY(-5deg);
}

.reflection-container .reflection-grid-cell-34:hover~.reflection-content:before {
    transform: translateY(5%);
}

.reflection-container .reflection-grid-cell-35 {
    top: 30%;
    left: 40%;
}

.reflection-container .reflection-grid-cell-35:hover~.reflection-content {
    transform: rotateX(5deg) rotateY(0deg);
}

.reflection-container .reflection-grid-cell-35:hover~.reflection-content:before {
    transform: translateY(5%);
}

.reflection-container .reflection-grid-cell-36 {
    top: 30%;
    left: 50%;
}

.reflection-container .reflection-grid-cell-36:hover~.reflection-content {
    transform: rotateX(5deg) rotateY(5deg);
}

.reflection-container .reflection-grid-cell-36:hover~.reflection-content:before {
    transform: translateY(5%);
}

.reflection-container .reflection-grid-cell-37 {
    top: 30%;
    left: 60%;
}

.reflection-container .reflection-grid-cell-37:hover~.reflection-content {
    transform: rotateX(5deg) rotateY(10deg);
}

.reflection-container .reflection-grid-cell-37:hover~.reflection-content:before {
    transform: translateY(5%);
}

.reflection-container .reflection-grid-cell-38 {
    top: 30%;
    left: 70%;
}

.reflection-container .reflection-grid-cell-38:hover~.reflection-content {
    transform: rotateX(5deg) rotateY(15deg);
}

.reflection-container .reflection-grid-cell-38:hover~.reflection-content:before {
    transform: translateY(5%);
}

.reflection-container .reflection-grid-cell-39 {
    top: 30%;
    left: 80%;
}

.reflection-container .reflection-grid-cell-39:hover~.reflection-content {
    transform: rotateX(5deg) rotateY(20deg);
}

.reflection-container .reflection-grid-cell-39:hover~.reflection-content:before {
    transform: translateY(5%);
}

.reflection-container .reflection-grid-cell-40 {
    top: 30%;
    left: 90%;
}

.reflection-container .reflection-grid-cell-40:hover~.reflection-content {
    transform: rotateX(5deg) rotateY(25deg);
}

.reflection-container .reflection-grid-cell-40:hover~.reflection-content:before {
    transform: translateY(5%);
}

.reflection-container .reflection-grid-cell-41 {
    top: 40%;
    left: 0%;
}

.reflection-container .reflection-grid-cell-41:hover~.reflection-content {
    transform: rotateX(0deg) rotateY(-20deg);
}

.reflection-container .reflection-grid-cell-41:hover~.reflection-content:before {
    transform: translateY(0%);
}

.reflection-container .reflection-grid-cell-42 {
    top: 40%;
    left: 10%;
}

.reflection-container .reflection-grid-cell-42:hover~.reflection-content {
    transform: rotateX(0deg) rotateY(-15deg);
}

.reflection-container .reflection-grid-cell-42:hover~.reflection-content:before {
    transform: translateY(0%);
}

.reflection-container .reflection-grid-cell-43 {
    top: 40%;
    left: 20%;
}

.reflection-container .reflection-grid-cell-43:hover~.reflection-content {
    transform: rotateX(0deg) rotateY(-10deg);
}

.reflection-container .reflection-grid-cell-43:hover~.reflection-content:before {
    transform: translateY(0%);
}

.reflection-container .reflection-grid-cell-44 {
    top: 40%;
    left: 30%;
}

.reflection-container .reflection-grid-cell-44:hover~.reflection-content {
    transform: rotateX(0deg) rotateY(-5deg);
}

.reflection-container .reflection-grid-cell-44:hover~.reflection-content:before {
    transform: translateY(0%);
}

.reflection-container .reflection-grid-cell-45 {
    top: 40%;
    left: 40%;
}

.reflection-container .reflection-grid-cell-45:hover~.reflection-content {
    transform: rotateX(0deg) rotateY(0deg);
}

.reflection-container .reflection-grid-cell-45:hover~.reflection-content:before {
    transform: translateY(0%);
}

.reflection-container .reflection-grid-cell-46 {
    top: 40%;
    left: 50%;
}

.reflection-container .reflection-grid-cell-46:hover~.reflection-content {
    transform: rotateX(0deg) rotateY(5deg);
}

.reflection-container .reflection-grid-cell-46:hover~.reflection-content:before {
    transform: translateY(0%);
}

.reflection-container .reflection-grid-cell-47 {
    top: 40%;
    left: 60%;
}

.reflection-container .reflection-grid-cell-47:hover~.reflection-content {
    transform: rotateX(0deg) rotateY(10deg);
}

.reflection-container .reflection-grid-cell-47:hover~.reflection-content:before {
    transform: translateY(0%);
}

.reflection-container .reflection-grid-cell-48 {
    top: 40%;
    left: 70%;
}

.reflection-container .reflection-grid-cell-48:hover~.reflection-content {
    transform: rotateX(0deg) rotateY(15deg);
}

.reflection-container .reflection-grid-cell-48:hover~.reflection-content:before {
    transform: translateY(0%);
}

.reflection-container .reflection-grid-cell-49 {
    top: 40%;
    left: 80%;
}

.reflection-container .reflection-grid-cell-49:hover~.reflection-content {
    transform: rotateX(0deg) rotateY(20deg);
}

.reflection-container .reflection-grid-cell-49:hover~.reflection-content:before {
    transform: translateY(0%);
}

.reflection-container .reflection-grid-cell-50 {
    top: 40%;
    left: 90%;
}

.reflection-container .reflection-grid-cell-50:hover~.reflection-content {
    transform: rotateX(0deg) rotateY(25deg);
}

.reflection-container .reflection-grid-cell-50:hover~.reflection-content:before {
    transform: translateY(0%);
}

.reflection-container .reflection-grid-cell-51 {
    top: 50%;
    left: 0%;
}

.reflection-container .reflection-grid-cell-51:hover~.reflection-content {
    transform: rotateX(-5deg) rotateY(-20deg);
}

.reflection-container .reflection-grid-cell-51:hover~.reflection-content:before {
    transform: translateY(-5%);
}

.reflection-container .reflection-grid-cell-52 {
    top: 50%;
    left: 10%;
}

.reflection-container .reflection-grid-cell-52:hover~.reflection-content {
    transform: rotateX(-5deg) rotateY(-15deg);
}

.reflection-container .reflection-grid-cell-52:hover~.reflection-content:before {
    transform: translateY(-5%);
}

.reflection-container .reflection-grid-cell-53 {
    top: 50%;
    left: 20%;
}

.reflection-container .reflection-grid-cell-53:hover~.reflection-content {
    transform: rotateX(-5deg) rotateY(-10deg);
}

.reflection-container .reflection-grid-cell-53:hover~.reflection-content:before {
    transform: translateY(-5%);
}

.reflection-container .reflection-grid-cell-54 {
    top: 50%;
    left: 30%;
}

.reflection-container .reflection-grid-cell-54:hover~.reflection-content {
    transform: rotateX(-5deg) rotateY(-5deg);
}

.reflection-container .reflection-grid-cell-54:hover~.reflection-content:before {
    transform: translateY(-5%);
}

.reflection-container .reflection-grid-cell-55 {
    top: 50%;
    left: 40%;
}

.reflection-container .reflection-grid-cell-55:hover~.reflection-content {
    transform: rotateX(-5deg) rotateY(0deg);
}

.reflection-container .reflection-grid-cell-55:hover~.reflection-content:before {
    transform: translateY(-5%);
}

.reflection-container .reflection-grid-cell-56 {
    top: 50%;
    left: 50%;
}

.reflection-container .reflection-grid-cell-56:hover~.reflection-content {
    transform: rotateX(-5deg) rotateY(5deg);
}

.reflection-container .reflection-grid-cell-56:hover~.reflection-content:before {
    transform: translateY(-5%);
}

.reflection-container .reflection-grid-cell-57 {
    top: 50%;
    left: 60%;
}

.reflection-container .reflection-grid-cell-57:hover~.reflection-content {
    transform: rotateX(-5deg) rotateY(10deg);
}

.reflection-container .reflection-grid-cell-57:hover~.reflection-content:before {
    transform: translateY(-5%);
}

.reflection-container .reflection-grid-cell-58 {
    top: 50%;
    left: 70%;
}

.reflection-container .reflection-grid-cell-58:hover~.reflection-content {
    transform: rotateX(-5deg) rotateY(15deg);
}

.reflection-container .reflection-grid-cell-58:hover~.reflection-content:before {
    transform: translateY(-5%);
}

.reflection-container .reflection-grid-cell-59 {
    top: 50%;
    left: 80%;
}

.reflection-container .reflection-grid-cell-59:hover~.reflection-content {
    transform: rotateX(-5deg) rotateY(20deg);
}

.reflection-container .reflection-grid-cell-59:hover~.reflection-content:before {
    transform: translateY(-5%);
}

.reflection-container .reflection-grid-cell-60 {
    top: 50%;
    left: 90%;
}

.reflection-container .reflection-grid-cell-60:hover~.reflection-content {
    transform: rotateX(-5deg) rotateY(25deg);
}

.reflection-container .reflection-grid-cell-60:hover~.reflection-content:before {
    transform: translateY(-5%);
}

.reflection-container .reflection-grid-cell-61 {
    top: 60%;
    left: 0%;
}

.reflection-container .reflection-grid-cell-61:hover~.reflection-content {
    transform: rotateX(-10deg) rotateY(-20deg);
}

.reflection-container .reflection-grid-cell-61:hover~.reflection-content:before {
    transform: translateY(-10%);
}

.reflection-container .reflection-grid-cell-62 {
    top: 60%;
    left: 10%;
}

.reflection-container .reflection-grid-cell-62:hover~.reflection-content {
    transform: rotateX(-10deg) rotateY(-15deg);
}

.reflection-container .reflection-grid-cell-62:hover~.reflection-content:before {
    transform: translateY(-10%);
}

.reflection-container .reflection-grid-cell-63 {
    top: 60%;
    left: 20%;
}

.reflection-container .reflection-grid-cell-63:hover~.reflection-content {
    transform: rotateX(-10deg) rotateY(-10deg);
}

.reflection-container .reflection-grid-cell-63:hover~.reflection-content:before {
    transform: translateY(-10%);
}

.reflection-container .reflection-grid-cell-64 {
    top: 60%;
    left: 30%;
}

.reflection-container .reflection-grid-cell-64:hover~.reflection-content {
    transform: rotateX(-10deg) rotateY(-5deg);
}

.reflection-container .reflection-grid-cell-64:hover~.reflection-content:before {
    transform: translateY(-10%);
}

.reflection-container .reflection-grid-cell-65 {
    top: 60%;
    left: 40%;
}

.reflection-container .reflection-grid-cell-65:hover~.reflection-content {
    transform: rotateX(-10deg) rotateY(0deg);
}

.reflection-container .reflection-grid-cell-65:hover~.reflection-content:before {
    transform: translateY(-10%);
}

.reflection-container .reflection-grid-cell-66 {
    top: 60%;
    left: 50%;
}

.reflection-container .reflection-grid-cell-66:hover~.reflection-content {
    transform: rotateX(-10deg) rotateY(5deg);
}

.reflection-container .reflection-grid-cell-66:hover~.reflection-content:before {
    transform: translateY(-10%);
}

.reflection-container .reflection-grid-cell-67 {
    top: 60%;
    left: 60%;
}

.reflection-container .reflection-grid-cell-67:hover~.reflection-content {
    transform: rotateX(-10deg) rotateY(10deg);
}

.reflection-container .reflection-grid-cell-67:hover~.reflection-content:before {
    transform: translateY(-10%);
}

.reflection-container .reflection-grid-cell-68 {
    top: 60%;
    left: 70%;
}

.reflection-container .reflection-grid-cell-68:hover~.reflection-content {
    transform: rotateX(-10deg) rotateY(15deg);
}

.reflection-container .reflection-grid-cell-68:hover~.reflection-content:before {
    transform: translateY(-10%);
}

.reflection-container .reflection-grid-cell-69 {
    top: 60%;
    left: 80%;
}

.reflection-container .reflection-grid-cell-69:hover~.reflection-content {
    transform: rotateX(-10deg) rotateY(20deg);
}

.reflection-container .reflection-grid-cell-69:hover~.reflection-content:before {
    transform: translateY(-10%);
}

.reflection-container .reflection-grid-cell-70 {
    top: 60%;
    left: 90%;
}

.reflection-container .reflection-grid-cell-70:hover~.reflection-content {
    transform: rotateX(-10deg) rotateY(25deg);
}

.reflection-container .reflection-grid-cell-70:hover~.reflection-content:before {
    transform: translateY(-10%);
}

.reflection-container .reflection-grid-cell-71 {
    top: 70%;
    left: 0%;
}

.reflection-container .reflection-grid-cell-71:hover~.reflection-content {
    transform: rotateX(-15deg) rotateY(-20deg);
}

.reflection-container .reflection-grid-cell-71:hover~.reflection-content:before {
    transform: translateY(-15%);
}

.reflection-container .reflection-grid-cell-72 {
    top: 70%;
    left: 10%;
}

.reflection-container .reflection-grid-cell-72:hover~.reflection-content {
    transform: rotateX(-15deg) rotateY(-15deg);
}

.reflection-container .reflection-grid-cell-72:hover~.reflection-content:before {
    transform: translateY(-15%);
}

.reflection-container .reflection-grid-cell-73 {
    top: 70%;
    left: 20%;
}

.reflection-container .reflection-grid-cell-73:hover~.reflection-content {
    transform: rotateX(-15deg) rotateY(-10deg);
}

.reflection-container .reflection-grid-cell-73:hover~.reflection-content:before {
    transform: translateY(-15%);
}

.reflection-container .reflection-grid-cell-74 {
    top: 70%;
    left: 30%;
}

.reflection-container .reflection-grid-cell-74:hover~.reflection-content {
    transform: rotateX(-15deg) rotateY(-5deg);
}

.reflection-container .reflection-grid-cell-74:hover~.reflection-content:before {
    transform: translateY(-15%);
}

.reflection-container .reflection-grid-cell-75 {
    top: 70%;
    left: 40%;
}

.reflection-container .reflection-grid-cell-75:hover~.reflection-content {
    transform: rotateX(-15deg) rotateY(0deg);
}

.reflection-container .reflection-grid-cell-75:hover~.reflection-content:before {
    transform: translateY(-15%);
}

.reflection-container .reflection-grid-cell-76 {
    top: 70%;
    left: 50%;
}

.reflection-container .reflection-grid-cell-76:hover~.reflection-content {
    transform: rotateX(-15deg) rotateY(5deg);
}

.reflection-container .reflection-grid-cell-76:hover~.reflection-content:before {
    transform: translateY(-15%);
}

.reflection-container .reflection-grid-cell-77 {
    top: 70%;
    left: 60%;
}

.reflection-container .reflection-grid-cell-77:hover~.reflection-content {
    transform: rotateX(-15deg) rotateY(10deg);
}

.reflection-container .reflection-grid-cell-77:hover~.reflection-content:before {
    transform: translateY(-15%);
}

.reflection-container .reflection-grid-cell-78 {
    top: 70%;
    left: 70%;
}

.reflection-container .reflection-grid-cell-78:hover~.reflection-content {
    transform: rotateX(-15deg) rotateY(15deg);
}

.reflection-container .reflection-grid-cell-78:hover~.reflection-content:before {
    transform: translateY(-15%);
}

.reflection-container .reflection-grid-cell-79 {
    top: 70%;
    left: 80%;
}

.reflection-container .reflection-grid-cell-79:hover~.reflection-content {
    transform: rotateX(-15deg) rotateY(20deg);
}

.reflection-container .reflection-grid-cell-79:hover~.reflection-content:before {
    transform: translateY(-15%);
}

.reflection-container .reflection-grid-cell-80 {
    top: 70%;
    left: 90%;
}

.reflection-container .reflection-grid-cell-80:hover~.reflection-content {
    transform: rotateX(-15deg) rotateY(25deg);
}

.reflection-container .reflection-grid-cell-80:hover~.reflection-content:before {
    transform: translateY(-15%);
}

.reflection-container .reflection-grid-cell-81 {
    top: 80%;
    left: 0%;
}

.reflection-container .reflection-grid-cell-81:hover~.reflection-content {
    transform: rotateX(-20deg) rotateY(-20deg);
}

.reflection-container .reflection-grid-cell-81:hover~.reflection-content:before {
    transform: translateY(-20%);
}

.reflection-container .reflection-grid-cell-82 {
    top: 80%;
    left: 10%;
}

.reflection-container .reflection-grid-cell-82:hover~.reflection-content {
    transform: rotateX(-20deg) rotateY(-15deg);
}

.reflection-container .reflection-grid-cell-82:hover~.reflection-content:before {
    transform: translateY(-20%);
}

.reflection-container .reflection-grid-cell-83 {
    top: 80%;
    left: 20%;
}

.reflection-container .reflection-grid-cell-83:hover~.reflection-content {
    transform: rotateX(-20deg) rotateY(-10deg);
}

.reflection-container .reflection-grid-cell-83:hover~.reflection-content:before {
    transform: translateY(-20%);
}

.reflection-container .reflection-grid-cell-84 {
    top: 80%;
    left: 30%;
}

.reflection-container .reflection-grid-cell-84:hover~.reflection-content {
    transform: rotateX(-20deg) rotateY(-5deg);
}

.reflection-container .reflection-grid-cell-84:hover~.reflection-content:before {
    transform: translateY(-20%);
}

.reflection-container .reflection-grid-cell-85 {
    top: 80%;
    left: 40%;
}

.reflection-container .reflection-grid-cell-85:hover~.reflection-content {
    transform: rotateX(-20deg) rotateY(0deg);
}

.reflection-container .reflection-grid-cell-85:hover~.reflection-content:before {
    transform: translateY(-20%);
}

.reflection-container .reflection-grid-cell-86 {
    top: 80%;
    left: 50%;
}

.reflection-container .reflection-grid-cell-86:hover~.reflection-content {
    transform: rotateX(-20deg) rotateY(5deg);
}

.reflection-container .reflection-grid-cell-86:hover~.reflection-content:before {
    transform: translateY(-20%);
}

.reflection-container .reflection-grid-cell-87 {
    top: 80%;
    left: 60%;
}

.reflection-container .reflection-grid-cell-87:hover~.reflection-content {
    transform: rotateX(-20deg) rotateY(10deg);
}

.reflection-container .reflection-grid-cell-87:hover~.reflection-content:before {
    transform: translateY(-20%);
}

.reflection-container .reflection-grid-cell-88 {
    top: 80%;
    left: 70%;
}

.reflection-container .reflection-grid-cell-88:hover~.reflection-content {
    transform: rotateX(-20deg) rotateY(15deg);
}

.reflection-container .reflection-grid-cell-88:hover~.reflection-content:before {
    transform: translateY(-20%);
}

.reflection-container .reflection-grid-cell-89 {
    top: 80%;
    left: 80%;
}

.reflection-container .reflection-grid-cell-89:hover~.reflection-content {
    transform: rotateX(-20deg) rotateY(20deg);
}

.reflection-container .reflection-grid-cell-89:hover~.reflection-content:before {
    transform: translateY(-20%);
}

.reflection-container .reflection-grid-cell-90 {
    top: 80%;
    left: 90%;
}

.reflection-container .reflection-grid-cell-90:hover~.reflection-content {
    transform: rotateX(-20deg) rotateY(25deg);
}

.reflection-container .reflection-grid-cell-90:hover~.reflection-content:before {
    transform: translateY(-20%);
}

.reflection-container .reflection-grid-cell-91 {
    top: 90%;
    left: 0%;
}

.reflection-container .reflection-grid-cell-91:hover~.reflection-content {
    transform: rotateX(-25deg) rotateY(-20deg);
}

.reflection-container .reflection-grid-cell-91:hover~.reflection-content:before {
    transform: translateY(-25%);
}

.reflection-container .reflection-grid-cell-92 {
    top: 90%;
    left: 10%;
}

.reflection-container .reflection-grid-cell-92:hover~.reflection-content {
    transform: rotateX(-25deg) rotateY(-15deg);
}

.reflection-container .reflection-grid-cell-92:hover~.reflection-content:before {
    transform: translateY(-25%);
}

.reflection-container .reflection-grid-cell-93 {
    top: 90%;
    left: 20%;
}

.reflection-container .reflection-grid-cell-93:hover~.reflection-content {
    transform: rotateX(-25deg) rotateY(-10deg);
}

.reflection-container .reflection-grid-cell-93:hover~.reflection-content:before {
    transform: translateY(-25%);
}

.reflection-container .reflection-grid-cell-94 {
    top: 90%;
    left: 30%;
}

.reflection-container .reflection-grid-cell-94:hover~.reflection-content {
    transform: rotateX(-25deg) rotateY(-5deg);
}

.reflection-container .reflection-grid-cell-94:hover~.reflection-content:before {
    transform: translateY(-25%);
}

.reflection-container .reflection-grid-cell-95 {
    top: 90%;
    left: 40%;
}

.reflection-container .reflection-grid-cell-95:hover~.reflection-content {
    transform: rotateX(-25deg) rotateY(0deg);
}

.reflection-container .reflection-grid-cell-95:hover~.reflection-content:before {
    transform: translateY(-25%);
}

.reflection-container .reflection-grid-cell-96 {
    top: 90%;
    left: 50%;
}

.reflection-container .reflection-grid-cell-96:hover~.reflection-content {
    transform: rotateX(-25deg) rotateY(5deg);
}

.reflection-container .reflection-grid-cell-96:hover~.reflection-content:before {
    transform: translateY(-25%);
}

.reflection-container .reflection-grid-cell-97 {
    top: 90%;
    left: 60%;
}

.reflection-container .reflection-grid-cell-97:hover~.reflection-content {
    transform: rotateX(-25deg) rotateY(10deg);
}

.reflection-container .reflection-grid-cell-97:hover~.reflection-content:before {
    transform: translateY(-25%);
}

.reflection-container .reflection-grid-cell-98 {
    top: 90%;
    left: 70%;
}

.reflection-container .reflection-grid-cell-98:hover~.reflection-content {
    transform: rotateX(-25deg) rotateY(15deg);
}

.reflection-container .reflection-grid-cell-98:hover~.reflection-content:before {
    transform: translateY(-25%);
}

.reflection-container .reflection-grid-cell-99 {
    top: 90%;
    left: 80%;
}

.reflection-container .reflection-grid-cell-99:hover~.reflection-content {
    transform: rotateX(-25deg) rotateY(20deg);
}

.reflection-container .reflection-grid-cell-99:hover~.reflection-content:before {
    transform: translateY(-25%);
}

.reflection-container .reflection-grid-cell-100 {
    top: 90%;
    left: 90%;
}

.reflection-container .reflection-grid-cell-100:hover~.reflection-content {
    transform: rotateX(-25deg) rotateY(25deg);
}

.reflection-container .reflection-grid-cell-100:hover~.reflection-content:before {
    transform: translateY(-25%);
}

.Figcaptionserveices {
    display: block;
    width: 70%;
    margin-top: 12%;
    padding: 8px 22px;
    font-weight: bold;
    line-height: 1.6;
    letter-spacing: 2px;
    word-spacing: 6px;
    text-align: center;
    position: absolute;
    top: 0;
    right: 30px;
    color: white;
    background: hsla(var(--hue), 25%, 10%, 0.5);
    font-size: 1.6rem;
    z-index: 1;
}

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #031163;
    border-radius: 20px;
    border: 3px solid transparent;
}


/* .reflection-container a:hover .Figcaptionserveices {
    display: block;
} */

.layputpadding {
    padding-top: 150px;
}

@media (max-width: 600px) {
    .layout-header h2 {
        font-size: 1.6rem;
    }
    .subtitle {
        font-size: 3rem;
    }
    /* .layputpadding {
        padding-top: 0px;
    } */
}

.product-form-collection-image {
    width: 100px;
    height: 100px;
    position: relative;
    display: inline-block;
    margin: 10px;
    background: #f4f4f4;
    border: 1px solid #cacaca;
}

.product-form-collection-image img,
.product-form-image-preview {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.product-form-field {
    width: 100%;
}

.is-img-loaded {
    animation: fadeIn .3s ease;
    opacity: 1;
}

.product-form-delete-image {
    color: red;
    border: none;
    background: none;
    position: absolute;
    top: -10px;
    right: -90px;
}

.product-form-delete-image:hover {
    color: #c00;
}

.product-form-delete-image i {
    background: #fff;
    border-radius: 50%;
}